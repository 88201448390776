import React, { useState } from "react";
import {
    Box,
    TextField,
    Button,
    Select,
    MenuItem,
    Typography,
    InputLabel,
} from "@mui/material";
import Dropzone from "react-dropzone";
import moment from "moment";

const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    bgcolor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1300, // Ensures it appears above other UI elements
};

const contentStyle = {
    position: "relative",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

const LorPopupEdit = ({
    open,
    handleClose,
    onChange,
    formData,
    handleSubmit,
    uploadLorID,
    lorUpButtonColor,
    lorIDProofFileName,
}) => {
    const [errors, setErrors] = useState({}); // To track field errors

    const validateBahrainMobile = (mobile) => /^[369]\d{7}$/.test(mobile);

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const validateCPR = (cpr) => /^\d{9}$/.test(cpr);

    const validateName = (name) => {
        return name.trim().includes(" ") && name.trim().split(" ").length >= 2;
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name || !validateName(formData.name)) {
            newErrors.name = "Full Name (First and Last) is required.";
        }
        if (!formData.designation) newErrors.designation = "Designation is required.";
        if (!formData.cpr || !validateCPR(formData.cpr)) newErrors.cpr = "Valid CPR is required.";
        if (!formData.mobile || !validateBahrainMobile(formData.mobile))
            newErrors.mobile = "Valid Bahrain mobile number is required.";
        if (!formData.email || !validateEmail(formData.email)) newErrors.email = "Valid email is required.";
        if (!formData.gender) newErrors.gender = "Gender is required.";
        if (!formData.title) newErrors.title = "Title is required.";
        if (!formData.dob) newErrors.dob = "Date of Birth is required.";

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0; // Return true if no errors
    };


    const onSave = () => {
        if (validateForm()) {
            handleSubmit(); // Call the original submit logic if valid
        }
    };

    if (!open) return null;

    return (
        <Box sx={overlayStyle}>
            <Box sx={contentStyle}>
                <Typography variant="h6" gutterBottom>
                    Edit Details
                </Typography>
                <form>
                    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={onChange}
                            error={!!errors.name}
                            helperText={errors.name || "Enter both First Name and Last Name separated by a space."}
                        />
                        <TextField
                            fullWidth
                            label="Designation"
                            name="designation"
                            value={formData.designation}
                            onChange={onChange}
                            error={!!errors.designation}
                            helperText={errors.designation}
                        />
                        <TextField
                            fullWidth
                            label="CPR"
                            name="cpr"
                            value={formData.cpr}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,9}$/.test(value)) {
                                    onChange({ target: { name: "cpr", value } });
                                }
                            }}
                            error={!!errors.cpr}
                            helperText={errors.cpr}
                        />
                        <TextField
                            fullWidth
                            label="Mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,8}$/.test(value)) {
                                    onChange({ target: { name: "mobile", value } });
                                }
                            }}
                            error={!!errors.mobile}
                            helperText={errors.mobile}
                        />
                        <TextField
                            fullWidth
                            label="LandLine"
                            name="landline"
                            value={formData.landline}
                            onChange={onChange}
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={onChange}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                        <InputLabel>Gender</InputLabel>
                        <Select
                            fullWidth
                            name="gender"
                            value={formData.gender}
                            onChange={onChange}
                            error={!!errors.gender}
                        >
                            <MenuItem value="">Select Gender</MenuItem>
                            <MenuItem value="M">Male</MenuItem>
                            <MenuItem value="F">Female</MenuItem>
                            <MenuItem value="O">Other</MenuItem>
                        </Select>
                        <Typography color="error">{errors.gender}</Typography>
                        <InputLabel>Title</InputLabel>
                        <Select
                            fullWidth
                            name="title"
                            value={formData.title}
                            onChange={onChange}
                            error={!!errors.title}
                        >
                            <MenuItem value="MR">Mr.</MenuItem>
                            <MenuItem value="MRS">Mrs.</MenuItem>
                        </Select>
                        <Typography color="error">{errors.title}</Typography>
                        <TextField
                            fullWidth
                            type="date"
                            label="DOB"
                            name="dob"
                            InputLabelProps={{ shrink: true }}
                            value={formData.dob}
                            onChange={onChange}
                            InputProps={{
                                inputProps: { max: moment().format("YYYY-MM-DD") },
                            }}
                            error={!!errors.dob}
                            helperText={errors.dob}
                        />
                        {lorIDProofFileName ? (
                            <TextField
                                fullWidth
                                label="Certified copy of identification"
                                value={lorIDProofFileName}
                                InputProps={{ readOnly: true }}
                            />
                        ) : (
                            <Dropzone onDrop={uploadLorID}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Button size="sm" fullWidth color={lorUpButtonColor}>
                                            ID Proof
                                        </Button>
                                    </div>
                                )}
                            </Dropzone>
                        )}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                        <Button variant="outlined" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={onSave}>
                            Save
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
};

export default LorPopupEdit;
