import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert,
  Radio,
  Typography,
  Box,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import config from 'config.js';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  fontSize: '1.25rem', // Increase font size
  padding: theme.spacing(2), // Add more padding for larger size
  borderRadius: theme.shape.borderRadius * 2, // Smooth corners
  boxShadow: theme.shadows[4], // Add shadow for a raised effect
}));

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiAlert-root': {
    fontSize: '1.2rem', // Larger text
    padding: theme.spacing(2), // More padding for visibility
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[3],
  },
}));

const LegalRepsPopup = ({ open, onClose, companyID, setM2pLegalRepCpr,selectedCpr }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedLOR, setSelectedLOR] = useState(selectedCpr);
  const [selectedLOREmail, setSelectedLOREmail] = useState("");

  const [snackbar, setSnackbar] = useState({ open: false, severity: '', message: '' });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${config.url().baseURL}/get-legal-reps-list/${companyID}`);
      const result = response.data;
      if (result.err) {
        throw new Error(result.msg || 'Failed to fetch data');
      }
      setData(result.data);
      const email = result.data.find(item => item.cpr === selectedCpr)?.email;
      setSelectedLOREmail(email)

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectLOR = (cpr,email) => {
    setSelectedLOR(cpr);
    setM2pLegalRepCpr(selectedCpr);
    setSelectedLOREmail(email)
  };

  const handleConfirm = async () => {
    if (!selectedLOR) return;
    setLoading(true);
    try {
      const response = await axios.post(`${config.url().baseURL}/check-corporate-portal-mail-availablity`, {
        email: selectedLOREmail,
        companyId:companyID,
      });
      const resp = response?.data
      if(resp.err){
        setSnackbar({
          open: true,
          severity: 'error',
          message: resp?.msg?resp.msg:'It’s not you, it’s us. Please try again! 🙏',
        });
      }else if(resp.data.emailExists){
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Oops 😅, this email Id already linked to a BFC Pay Business account! 🚫',
        });
      }
      
      else{
      setSnackbar({
        open: true,
        severity: 'success',
        message: 'Email validation successful!',
      });
      setM2pLegalRepCpr(selectedLOR);
      onClose();

    }
    } catch (err) {
      console.log(err)
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Failed to validate email. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <>
      <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Select a Legal Representative</DialogTitle>
        <DialogContent>
          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </Box>
          )}
          {error && (
            <StyledAlert severity="error" sx={{ mb: 2 }}>
              {error}
            </StyledAlert>
          )}
          {!loading && !error && data.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Select</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>CPR</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>Mobile</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((rep) => (
                    <TableRow
                      key={rep.cpr}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleSelectLOR(rep.cpr,rep.email)}
                    >
                      <TableCell>
                        <Radio
                          checked={selectedLOR === rep.cpr}
                          onChange={() => handleSelectLOR(rep.cpr,rep.email)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>{rep.cpr}</TableCell>
                      <TableCell>{rep.name}</TableCell>
                      <TableCell>{rep.email}</TableCell>
                      <TableCell>{rep.mobile}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!loading && !error && data.length === 0 && (
            <Typography variant="body1" align="center" sx={{ mt: 4 }}>
              No data available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleOpenConfirmDialog}
            color="primary"
            variant="contained"
            disabled={!selectedLOR}
          >
            Confirm
          </Button>
        </DialogActions>
      </StyledDialog>

      {/* Confirmation Dialog */}
      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to select this LOR for Corporate?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
              handleCloseConfirmDialog();
            }}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <StyledSnackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <StyledAlert severity={snackbar.severity}>{snackbar.message}</StyledAlert>
      </StyledSnackbar>
    </>
  );
};

export default LegalRepsPopup;
