import React from "react";
import config from "config.js"
import Dropzone from "react-dropzone";
import libs from "controllers/libs";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import axios from "axios";
import MoonLoader from "react-spinners/MoonLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import download from "js-file-download";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Image from "assets/img/sidebar-2.jpg"
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import IBAN from 'iban'
import CustomSelectStyle from "assets/jss/material-dashboard-react/customSelectStyle";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { setCompanyID } from "redux/actions/corporateOnboarding";
import { useDispatch } from "react-redux";
import { setM2pWalletCreate } from "redux/actions/corporateOnboarding.js";
import { Grid, Switch } from '@mui/material';
import { Input, TextField } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import LegalRepsPopup from '../Components/LegalRepsPopup'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const styles = (theme) => ({
  ...SweetAlertStyle,
  ...CustomSelectStyle,
  subHead: {
    fontWeight: "500",
    marginBottom: "-2px !important",
    float: "left",
    display: "inlineBlock",
  },
  docName: {
    fontSize: "14px !important",
    fontWeight: "500",
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  uploadButton: {
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  required: {
    color: "red",
  },
  sideHead: {
    fontWeight: "500",
    fontSize: "14px",
    marginBottom: "0px !important",
    marginTop: "25px !important",
    color: "#474747",
  },
  addActions: {
    float: "right",
    display: "inlineBlock",
  },
  question: {
    fontSize: "18px",
    fontWeight: "400",
  },
  radioGroup: {
    marginTop: "-20px !important",
  },
  radioGroupFee: {
    height: "25px !important",
    overFlow: "hidden"
  },
  dateLabel: {
    marginTop: "-18px",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "12px",
    fontWeight: "400",
  },
  dateInput: {
    marginTop: "20px !important",
  },
  addOwnerActions: {
    float: "right",
    display: "inlineBlock",
  },
  backButton: {
    float: "left !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  ...modalStyle(theme),
});

const override = css`
  display: block;
  margin: -10px;
  border-color: red;
`;

const useStyles = makeStyles(styles);
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function KYC_Documents(props) {
  var companyID = useSelector((state) => state.corporateOnboarding.companyID);
  const userGmail = useSelector((state) => state.user.email);
  const businessData = useSelector(state => state.corporateOnboarding.businessProfile);

  const m2pWalletStatus = useSelector(state => state.corporateOnboarding.businessProfile.m2pWalletStatus);
  const m2pWalletCreateRedux = useSelector(state => state.corporateOnboarding.businessProfile.m2pWalletCreate);

  const [m2pWalletCreate, setM2pWalletCreateLocal] = React.useState(m2pWalletCreateRedux);
  const today = new Date().toISOString().split('T')[0];
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [isPopupOpen, setPopupOpen] = React.useState(true);

  // setM2pWalletStatus(M2pWalletStatusRedux)
  //const companyID = "611cba34f0dbbb7ab3a8fdbd";
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const [businessNature, setBusinessNature] = React.useState("");
  const [transactionActivity, setTransactionActivity] = React.useState("");
  const [serviceType, setServiceType] = React.useState("Prepaid Mobile Wallets");
  const [noOfEmployees, setNoOfEmployees] = React.useState("");
  const [avgTotalSalary, setAvgTotalSalary] = React.useState("");

  const [servicesData, setServicesData] = React.useState([]);
  const [servicesTableData, setServicesTableData] = React.useState([]);

  const [declarationName, setDeclarationName] = React.useState("");
  const [declarationDesignation, setDeclarationDesignation] = React.useState("");

  const [authorization, setAuthorization] = React.useState("");
  const [authorizationThirdParty, setAuthorizationThirdParty] = React.useState("");

  const [lorData, setLorData] = React.useState([]);
  const [lorTableData, setLorTableData] = React.useState([]);
  const [lorActualData, setLorActualData] = React.useState([]);

  const [lorName, setLorName] = React.useState("");
  const [lorDesignation, setLorDesignation] = React.useState("");
  const [lorCPR, setLorCPR] = React.useState("");
  const [lorDob, setLorDob] = React.useState("");
  const [lorTitle, setLorTitle] = React.useState("");
  const [lorGender, setLorGender] = React.useState("");
  const dispatch = useDispatch();


  const [lorEmail, setLorEmail] = React.useState("");
  const [lorMobile, setLorMobile] = React.useState("");
  const [lorLandLine, setLorLandLine] = React.useState("");
  const [lorIDProof, setLorIDProof] = React.useState("");
  const [lorIDProofFileName, setLorIDProofFileName] = React.useState("");
  const [lorUpButtonColor, setLorUpButtonColor] = React.useState("secondary");

  const [lorTableKey, setLorTableKey] = React.useState(0);

  const [assistedOfficer, setAssistedOfficer] = React.useState("");
  const [bdoName, setBdoName] = React.useState("");
  const [bdoEmail, setBdoEmail] = React.useState("");
  const [bdoEmpID, setBdoEmpID] = React.useState("");

  const [proposedSalaryDate, setProposedSalaryDate] = React.useState(moment(new Date()).format("YYYY-MM-DD"));

  const [serviceFeeType, setServiceFeeType] = React.useState("");
  const [serviceFee, setServiceFee] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");

  const [values, setValues] = React.useState({
    businessNatureErr: null,
    transactionActivityErr: null,
    serviceTypeErr: null,
    noOfEmployeesErr: null,
    avgTotalSalaryErr: null,
    declarationNameErr: null,
    declarationDesignationErr: null,
    authorizationErr: null,
    authorizationThirdPartyErr: null,
    lorNameErr: null,
    lorDesignationErr: null,
    lorCPRErr: null,
    lorGenderErr: null,
    lorTitleErr: null,
    lorDobErr: null,

    lorEmailErr: null,
    lorIDProofErr: null,
    lorMobileErr: null,
    lorLandlineErr: null,
    assistedOfficerErr: null,
    bdoNameErr: null,
    bdoEmailErr: null,
    bdoIDErr: null,
    proposedSalaryDateErr: null,
    serviceFeeErr: null,
    bankNameErr: null,
    accountNameErr: null,
    accountNumberErr: null,
    feeTypeErr: null,
    serviceFeeTypeErr: null
  });

  const [submitting, setSubmitting] = React.useState(false);
  const [respAlert, setRespAlert] = React.useState(null);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [showServiceAdd, setShowServiceAdd] = React.useState(true);
  const [showCorpDetails, setShowCorpDetails] = React.useState(false);
  const [showLorAdd, setShowLorAdd] = React.useState(true);
  const [m2pLegalRepCpr, setM2pLegalRepCpr] = React.useState("");

  const [commercialRegDoc, setCommercialRegDoc] = React.useState(1);
  const [regulatorsLicense, setRegulatorsLicense] = React.useState(1);
  const [memorandum, setMemorandum] = React.useState(1);
  const [financialRep, setFinancialRep] = React.useState(1);
  const [ownership, setOwnerShip] = React.useState(1);
  const [lorIDProofStatus, setLorIDProofStatus] = React.useState(1);
  const [corpRegForm, setCorpRegForm] = React.useState(1);
  const [corpAgreement, setCorpAgreement] = React.useState(1);
  const [vatCertificate, setVatCertificate] = React.useState(1);

  const [submitBtnLabel, setSubmitBtnLabel] = React.useState("SAVE");
  const [corpAgreementBtnClr, setCorpAgreementBtnClr] = React.useState("primary");
  const [corpRegBtnClr, setCorpRegBtnClr] = React.useState("primary");
  const [memorandumBtnClr, setMemorandumBtnClr] = React.useState("primary");
  const [ownershipBtnClr, setOwnershipBtnClr] = React.useState("primary");
  const [regulatorsLicenseBtnClr, setRegulatorsLicensepBtnClr] = React.useState("primary");
  const [comRegBtnClr, setComRegBtnClr] = React.useState("primary");
  const [finRepBtnClr, setFinRepBtnClr] = React.useState("primary");


  const [commercialRegDocData, setCommercialRegDocData] = React.useState("");
  const [regulatorsLicenseData, setRegulatorsLicenseData] = React.useState("");
  const [memorandumData, setMemorandumData] = React.useState("");
  const [financialRepData, setFinancialRepData] = React.useState("");
  const [ownershipData, setOwnerShipData] = React.useState("");
  const [lorIDProofStatusData, setLorIDProofStatusData] = React.useState("");
  const [corpRegFormData, setCorpRegFormData] = React.useState("");
  const [corpAgreementData, setCorpAgreementData] = React.useState("");
  const [varCertificateData, setVatCertificateData] = React.useState("");

  const [documentViewerData, setDocumentViewerData] = React.useState(Image);
  const [openImageViewer, setOpenImageViewer] = React.useState(false);
  const [openPDFeViewer, setOpenPDFViewer] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const [updated, setUpdated] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  const [showEditLor, setShowEditLor] = React.useState(false);
  const [lorEditID, setLorEditID] = React.useState("");
  const [editFlag, setEditFlag] = React.useState({ status: false, id: null });
  const [submitLabel, setSubmitLabel] = React.useState("Saving Data");
  const [selectedRow, setSelectedRow] = React.useState(null);


  const [serviceTypes, setServiceTypes] = React.useState([
    // {
    //   name: "Prepaid Cards",
    //   value: "Prepaid Cards",
    // },
    {
      name: "Prepaid Mobile Wallets",
      value: "Prepaid Mobile Wallets",
    },
    // {
    //   name: "Bank Transfer",
    //   value: "Bank Transfer",
    // },
  ]);
  const [editData, setEditData] = React.useState({});

  React.useEffect(() => {
    if (props.edit) {
      setBusinessData()
    }
  }, [props.edit]);

  React.useEffect(() => {
    if (props.cacheEdit) {
      console.log(props.id)
      setBackData(props.id)
    }
  }, [props.edit]);

  React.useEffect(() => {
    // Update local state when Redux state changes
    setM2pWalletCreateLocal(m2pWalletCreateRedux);
  }, [m2pWalletCreateRedux]);

  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   setM2pWalletStatusLocal(value);
  //   dispatch(setM2pWalletStatus(value));
  //   verify(value, "length", "authorizationErr", 3); // Ensure `verify` is defined in your scope
  // };

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleCLickVate = async (value) => {
    //  alert(values)
    //  if (value=="M2P") {
    setM2pWalletCreateLocal(!m2pWalletCreate)
    handlePopupOpen()
    dispatch(setM2pWalletCreate(!m2pWalletCreate));
    // }
  }

  //   React.useEffect(()=>{
  //     fetchCompanyDetials(props.id)
  //   },[])


  // const fetchCompanyDetials = (id)=>{
  //   try{
  //     await axios.get(`${config.url().baseURL}/get-company-log/${id}`).then((resp) => {

  //   }catch(err){
  //     console.log(err)
  //   }
  // }


  const setBackData = (id) => {
    //function to set data from middle ware db
    companyID = id
    setSubmitLabel("Fetching Data..")
    setSubmitting(true)
    axios.post(`${config.url().baseURL}/get-step-three-details`, { company: id }).then((resp) => {
      let respData = resp.data;
      if (respData.err) {
        setServicesData([])
        setLorActualData([]);
        setLorData([])
        lorToArray([]);
      } else {
        let data = respData.data
        setShowCorpDetails(true)
        setSubmitting(false)
        setTimeout(function () {
          setSubmitLabel("Saving Data..")
        }, 1000);

        setBusinessNature(data.description)
        setTransactionActivity(data.transaction_activity)
        setServiceType(data.services[0].type_of_service)
        setNoOfEmployees(parseInt(data.services[0].no_of_employee))
        setAvgTotalSalary(parseInt(data.services[0].average_total_salary))

        let tempJson = servicesData;
        tempJson.push({
          type_of_service: data.services[0].type_of_service,
          no_of_employee: data.services[0].type_of_service,
          average_total_salary: data.services[0].average_total_salary,
        });
        setServicesData(tempJson);
        serviceToArray(tempJson);
        deleteServiceType(data.services[0].type_of_service);
        cancelAddingService();
        setDeclarationName(data.declaration.name)
        setDeclarationDesignation(data.declaration.designation)
        setServiceFeeType(data.fee_type)
        if (data.authorisation_confirmation) {
          setAuthorization("true")
        } else {
          setAuthorization("false")
          setAuthorizationThirdParty(data.third_party_name)
        }

        tempJson = lorActualData;
        let tempTableJSON = lorData;

        Promise.all(data.letter_of_representation.map((lor, key) => {
          tempTableJSON.push({
            name: lor.name,
            designation: lor.designation,
            cpr: lor.cpr,
            mobile: lor.MobileNumber,
            landline: lor.landline ? lor.landline : "",
            email: lor.email,
            title: lor.title,
            id_proof: `ID_Proof_${lor.name}`
          });
          tempJson.push({
            name: lor.name,
            designation: lor.designation,
            cpr: lor.cpr,
            mobile: lor.MobileNumber,
            landline: lor.landline ? lor.landline : "",
            email: lor.email,
            id_proof: lor.id_proof,
            gender: lor.gender,
            title: lor.title,
            dob: lor.dob,
          });
        }))
        setLorActualData(tempJson);
        setLorData(tempTableJSON)
        lorToArray(tempTableJSON);
        setShowLorAdd(false);

        if (data.assisted_officers.status) {
          setAssistedOfficer("true")
          setBdoName(data.assisted_officers.officer_name)
          setBdoEmail(data.assisted_officers.officer_email)
          setBdoEmpID(data.assisted_officers.officer_emp_id)
        } else {
          setAssistedOfficer("false")
        }


        setServiceFee(data.corporate_details.service_fee)
        setBankName(data.corporate_details.bank_name)
        setAccountName(data.corporate_details.account_name)
        setAccountNumber(data.corporate_details.account_number)
        getKYCDocuments(id)
      }

    });
  }


  const getKYCDocuments = (id) => {
    //function to get all kyc documents from db
    setCorpRegForm(2)
    setCorpAgreement(2)
    setCommercialRegDoc(2)
    setRegulatorsLicense(2)
    setMemorandum(2)
    setFinancialRep(2)
    setOwnerShip(2)
    setVatCertificate(2)

    axios.post(`${config.url().baseURL}/get-company-kyc-docs`, { company: id }).then((resp) => {
      let respData = resp.data
      if (respData.err) {
        openSnackBar(respData.msg)
      } else {
        let docs = respData.docs
        if (docs.find(doc => doc.docType == "BUSINESS_REGISTRATION_FORM")) {
          let BRF = docs.find(doc => doc.docType == "BUSINESS_REGISTRATION_FORM")
          setCorpRegForm(3)
          setCorpRegFormData(BRF.doc)
        } else {
          setCorpRegForm(1)
        }
        if (docs.find(doc => doc.docType == "UPLOAD_CORPORATE_AGREEMENT_SIGNED")) {
          let CA = docs.find(doc => doc.docType == "UPLOAD_CORPORATE_AGREEMENT_SIGNED")
          setCorpAgreement(3)
          setCorpAgreementData(CA.doc)
        } else {
          setCorpAgreement(1)
        }
        if (docs.find(doc => doc.docType == "VALID_COMMERCIAL_REGISTRATION")) {
          let CR = docs.find(doc => doc.docType == "VALID_COMMERCIAL_REGISTRATION")
          setCommercialRegDoc(3)
          setCommercialRegDocData(CR.doc)
        } else {
          setCommercialRegDoc(1)
        }
        if (docs.find(doc => doc.docType == "REGULATOR_LICENSE")) {
          let RL = docs.find(doc => doc.docType == "REGULATOR_LICENSE")
          setRegulatorsLicense(3)
          setRegulatorsLicenseData(RL.doc)
        } else {
          setRegulatorsLicense(1)
        }
        if (docs.find(doc => doc.docType == "MEMORANUM_ARTICLES_OF_INCORPORATION")) {
          let MAI = docs.find(doc => doc.docType == "MEMORANUM_ARTICLES_OF_INCORPORATION")
          setMemorandum(3)
          setMemorandumData(MAI.doc)
        } else {
          setMemorandum(1)
        }
        if (docs.find(doc => doc.docType == "AUDITED_FINANCIALS")) {
          let AF = docs.find(doc => doc.docType == "AUDITED_FINANCIALS")
          setFinancialRep(3)
          setFinancialRepData(AF.doc)
        } else {
          setFinancialRep(1)
        }
        if (docs.find(doc => doc.docType == "OWNERSHIP_STRUCTURE")) {
          let AF = docs.find(doc => doc.docType == "OWNERSHIP_STRUCTURE")
          setOwnerShip(3)
          setOwnerShipData(AF.doc)
        } else {
          setOwnerShip(1)
        }
        if (docs.find(doc => doc.docType == "VAT_CERTIFICATE")) {
          let AF = docs.find(doc => doc.docType == "VAT_CERTIFICATE")
          setVatCertificate(3)
          setVatCertificateData(AF.doc)
        } else {
          setVatCertificate(1)
        }
      }
    })

  }

  const setBusinessData = () => {
    //function to set business data for edit
    setShowCorpDetails(true)
    setEditData(businessData)
    setBusinessNature(businessData.BusinessActivityProfile.NatureOfBusiness)
    setTransactionActivity(businessData.BusinessActivityProfile.SourceOfFunds)
    setServiceType(businessData.BusinessActivityProfile.TypeOfServiceRequired.TypeOfService)
    setNoOfEmployees(parseInt(businessData.BusinessActivityProfile.TypeOfServiceRequired.NoOfEmployees))
    setAvgTotalSalary(parseInt(businessData.BusinessActivityProfile.TypeOfServiceRequired.AverageTotalSalaryAmount))

    let tempJson = servicesData;
    tempJson.push({
      type_of_service: businessData.BusinessActivityProfile.TypeOfServiceRequired.TypeOfService,
      no_of_employee: businessData.BusinessActivityProfile.TypeOfServiceRequired.NoOfEmployees,
      average_total_salary: businessData.BusinessActivityProfile.TypeOfServiceRequired.AverageTotalSalaryAmount,
    });
    setServicesData(tempJson);
    serviceToArray(tempJson);
    deleteServiceType(businessData.BusinessActivityProfile.TypeOfServiceRequired.TypeOfService);
    cancelAddingService();
    setDeclarationName(businessData.ClientDeclaration.Name)
    setDeclarationDesignation(businessData.ClientDeclaration.Designation)
    setServiceFeeType(businessData.FeeType)
    if (businessData.AuthorisationAndConfirmation) {
      setAuthorization("true")
    } else {
      setAuthorization("false")
      setAuthorizationThirdParty(businessData.NameOfThirdParty)
    }
    tempJson = lorActualData;
    let tempTableJSON = lorData;
    Promise.all(businessData.LetterOfRepresentation.map((lor, key) => {
      if (lor.DocumentID) {
        tempJson.push({
          name: lor.Name,
          designation: lor.Designation,
          cpr: lor.CPRNumber,
          DocumentID: lor.DocumentID,
          email: lor.EmailAddress,
          mobile: lor.MobileNumber,
          landline: lor.LandLine ? lor.LandLine : "",
          gender: lor.gender,
          title: lor.title,
          dob: lor.dob
        });
      } else {
        tempJson.push({
          name: lor.Name,
          designation: lor.Designation,
          cpr: lor.CPRNumber,
          id_proof: "",
          email: lor.EmailAddress,
          mobile: lor.MobileNumber,
          landline: lor.LandLine ? lor.LandLine : "",
          gender: lor.gender,
          title: lor.title,
          dob: lor.dob
        });
      }

      tempTableJSON.push({
        name: lor.Name,
        designation: lor.Designation,
        cpr: lor.CPRNumber,
        mobile: lor.MobileNumber,
        landline: lor.LandLine ? lor.LandLine : "",
        email: lor.EmailAddress,
        id_proof: lor.DocumentID,
        gender: lor.gender,
        title: lor.title,
        dob: lor.dob
      });
    }))
    setLorActualData(tempJson);
    setLorData(tempTableJSON)
    lorToArray(tempTableJSON);
    setShowLorAdd(false);

    if (businessData.AssistedOfficer.AssistedBy == "YES") {
      setAssistedOfficer("true")
      setBdoName(businessData.AssistedOfficer.OfficerName)
      setBdoEmail(businessData.AssistedOfficer.OfficerEmail)
      setBdoEmpID(businessData.AssistedOfficer.EmployeeID)
    } else {
      setAssistedOfficer("false")
    }
    setServiceFee(businessData.BusinessRegistrationDetails.ServiceFeeInBHD)
    setBankName(businessData.BusinessRegistrationDetails.BankName)
    setAccountName(businessData.BusinessRegistrationDetails.AccountName)
    setAccountNumber(businessData.BusinessRegistrationDetails.AccountNumber)
    let docs = businessData.Document
    if (docs.find(doc => doc.DocumentType == "BUSINESS_REGISTRATION_FORM")) {
      let BRF = docs.find(doc => doc.DocumentType == "BUSINESS_REGISTRATION_FORM")
      setCorpRegForm(3)
      setCorpRegFormData(BRF.DocumentID)
    }
    if (docs.find(doc => doc.DocumentType == "UPLOAD_CORPORATE_AGREEMENT_SIGNED")) {
      let CA = docs.find(doc => doc.DocumentType == "UPLOAD_CORPORATE_AGREEMENT_SIGNED")
      setCorpAgreement(3)
      setCorpAgreementData(CA.DocumentID)
    }
    if (docs.find(doc => doc.DocumentType == "VALID_COMMERCIAL_REGISTRATION")) {
      let CR = docs.find(doc => doc.DocumentType == "VALID_COMMERCIAL_REGISTRATION")
      setCommercialRegDoc(3)
      setCommercialRegDocData(CR.DocumentID)
    }
    if (docs.find(doc => doc.DocumentType == "REGULATOR_LICENSE")) {
      let RL = docs.find(doc => doc.DocumentType == "REGULATOR_LICENSE")
      setRegulatorsLicense(3)
      setRegulatorsLicenseData(RL.DocumentID)
    }
    if (docs.find(doc => doc.DocumentType == "MEMORANUM_ARTICLES_OF_INCORPORATION")) {
      let MAI = docs.find(doc => doc.DocumentType == "MEMORANUM_ARTICLES_OF_INCORPORATION")
      setMemorandum(3)
      setMemorandumData(MAI.DocumentID)
    }
    if (docs.find(doc => doc.DocumentType == "AUDITED_FINANCIALS")) {
      let AF = docs.find(doc => doc.DocumentType == "AUDITED_FINANCIALS")
      setFinancialRep(3)
      setFinancialRepData(AF.DocumentID)
    }
    if (docs.find(doc => doc.DocumentType == "OWNERSHIP_STRUCTURE")) {
      let AF = docs.find(doc => doc.DocumentType == "OWNERSHIP_STRUCTURE")
      setOwnerShip(3)
      setOwnerShipData(AF.DocumentID)
    }
    if (docs.find(doc => doc.DocumentType == "VAT_CERTIFICATE")) {
      let AF = docs.find(doc => doc.DocumentType == "VAT_CERTIFICATE")
      setVatCertificate(3)
      setVatCertificateData(AF.DocumentID)
    }
  }


  const openSnackBar = (msg) => {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  };


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const deleteDoc = (type) => {
    //function to delete docs uploaded
    switch (type) {
      case "commercialReg":
        setCommercialRegDocData("")
        setCommercialRegDoc(1)
        break;

      case "regulatorsLicense":
        setRegulatorsLicenseData("")
        setRegulatorsLicense(1)
        break;

      case "memorandum":
        setMemorandumData("")
        setMemorandum(1)
        break;

      case "financialRep":
        setFinancialRepData("")
        setFinancialRep(1)
        break;

      case "ownership":
        setOwnerShipData("")
        setOwnerShip(1)
        break;
      case "corpRegForm":
        setCorpRegFormData("")
        setCorpRegForm(1)
        break;

      case "corpAgreement":
        setCorpAgreementData("")
        setCorpAgreement(1)
        break;
      case "vat":
        setVatCertificateData("")
        setVatCertificate(1)
        break;

      default:
        break;
    }
  }



  const setDocumentViewer = (type) => {
    //function to set document viewer data
    setPageNumber(1)
    switch (type) {
      case "commercialReg":
        if (props.edit) {
          viewKycDoc(commercialRegDocData)
        } else if (props.cacheEdit) {
          viewKycDoc(commercialRegDocData)
        } else {
          if (/\.(pdf)$/i.test(commercialRegDocData.name) == true) {
            setDocumentViewerData(commercialRegDocData)
            setOpenPDFViewer(true)
          } else {
            getBase64(commercialRegDocData, (result) => {
              setDocumentViewerData(result)
            });
            setOpenImageViewer(true)
          }
        }
        break;
      case "regulatorsLicense":
        if (props.edit) {
          viewKycDoc(regulatorsLicenseData)
        } else if (props.cacheEdit) {
          viewKycDoc(regulatorsLicenseData)
        }
        else {
          if (/\.(pdf)$/i.test(regulatorsLicenseData.name) == true) {
            setDocumentViewerData(regulatorsLicenseData)
            setOpenPDFViewer(true)
          } else {
            getBase64(regulatorsLicenseData, (result) => {
              setDocumentViewerData(result)
            });
            setOpenImageViewer(true)
          }
        }
        break;
      case "memorandum":
        if (props.edit) {
          viewKycDoc(memorandumData)
        }
        else if (props.cacheEdit) {
          viewKycDoc(memorandumData)
        } else {
          if (/\.(pdf)$/i.test(memorandumData.name) == true) {
            setDocumentViewerData(memorandumData)
            setOpenPDFViewer(true)
          } else {
            getBase64(memorandumData, (result) => {
              setDocumentViewerData(result)
            });
            setOpenImageViewer(true)
          }
        }
        break;
      case "financialRep":
        if (props.edit) {
          viewKycDoc(financialRepData)
        } else if (props.cacheEdit) {
          viewKycDoc(financialRepData)
        } else {
          if (/\.(pdf)$/i.test(financialRepData.name) == true) {
            setDocumentViewerData(financialRepData)
            setOpenPDFViewer(true)
          } else {
            getBase64(financialRepData, (result) => {
              setDocumentViewerData(result)
            });
            setOpenImageViewer(true)
          }
        }
        break;
      case "ownership":
        if (props.edit) {
          viewKycDoc(ownershipData)
        } else if (props.cacheEdit) {
          viewKycDoc(ownershipData)
        } else {
          if (/\.(pdf)$/i.test(ownershipData.name) == true) {
            setDocumentViewerData(ownershipData)
            setOpenPDFViewer(true)
          } else {
            getBase64(ownershipData, (result) => {
              setDocumentViewerData(result)
            });
            setOpenImageViewer(true)
          }
        }
        break;
      case "corpRegForm":
        if (props.edit) {
          viewKycDoc(corpRegFormData)
        } else if (props.cacheEdit) {
          viewKycDoc(corpRegFormData)
        } else {
          if (/\.(pdf)$/i.test(corpRegFormData.name) == true) {
            setDocumentViewerData(corpRegFormData)
            setOpenPDFViewer(true)
          } else {
            getBase64(corpRegFormData, (result) => {
              setDocumentViewerData(result)
            });
            setOpenImageViewer(true)
          }
        }
        break;
      case "corpAgreement":
        if (props.edit) {
          viewKycDoc(corpAgreementData)
        } else if (props.cacheEdit) {
          viewKycDoc(corpAgreementData)
        } else {
          if (/\.(pdf)$/i.test(corpAgreementData.name) == true) {

            setDocumentViewerData(corpAgreementData)
            setOpenPDFViewer(true)
          } else {
            getBase64(corpAgreementData, (result) => {
              setDocumentViewerData(result)
            });
            setOpenImageViewer(true)
          }
        }
        break;

      case "vat":
        if (props.edit) {
          viewKycDoc(varCertificateData)
        } else if (props.cacheEdit) {
          viewKycDoc(varCertificateData)
        } else {
          if (/\.(pdf)$/i.test(varCertificateData.name) == true) {
            setDocumentViewerData(varCertificateData)
            setOpenPDFViewer(true)
          } else {
            getBase64(varCertificateData, (result) => {
              setDocumentViewerData(result)
            });
            setOpenImageViewer(true)
          }
        }
        break;

      default:
        break;
    }

  }

  const viewKycDoc = (id) => {
    //function to view kyc docs
    setPageNumber(1)
    setDocumentViewerData(null)
    if (props.cacheEdit) {
      let extension = id.substring(id.indexOf("/") + 1, id.indexOf(";base64"));
      if (extension == "pdf") {
        setDocumentViewerData(id)
        setOpenPDFViewer(true)
      } else {
        setDocumentViewerData(id)
        setOpenImageViewer(true)
      }
    } else {
      if (typeof id == 'object') {
        if (/\.(pdf)$/i.test(id.name) == true) {
          setDocumentViewerData(id)
          setOpenPDFViewer(true)
        } else {
          getBase64(id, (result) => {
            setDocumentViewerData(result)
          });
          setOpenImageViewer(true)
        }
      } else {
        axios.post(`${config.url().baseURL}/get-company-docs-id/${id}`).then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            let extension = respData.data.Content.substring(respData.data.Content.indexOf("/") + 1, respData.data.Content.indexOf(";base64"));
            setDocumentViewerData(respData.data.Content, respData.data.DocumentType)
            if (extension == "pdf") {
              setOpenPDFViewer(true)
              setOpenImageViewer(false)
            } else {
              setOpenImageViewer(true)
              setOpenPDFViewer(false)
            }

          } else {
            setDocumentViewerData(null)
            openSnackBar(respData.msg);
          }
        });
      }
    }
  }

  const idViewer = (id) => {
    //function to set id proof viewer
    setPageNumber(1)
    if (props.edit) {
      if (lorActualData[id].id_proof) {
        let file = lorActualData[id].id_proof
        let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
        if (extension == "pdf") {
          console.log(file)
          setDocumentViewerData(file)
          setOpenPDFViewer(true)
        } else {
          setDocumentViewerData(file)
          setOpenImageViewer(true)
        }
      } else {
        let docID = lorData[id].id_proof
        setDocumentViewerData(null)
        axios.get(`${config.url().baseURL}/get-company-docs-id/${docID}`).then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            let extension = respData.data.Content.substring(respData.data.Content.indexOf("/") + 1, respData.data.Content.indexOf(";base64"));
            setDocumentViewerData(respData.data.Content, respData.data.DocumentType)
            if (extension == "pdf") {
              setOpenPDFViewer(true)
              setOpenImageViewer(false)
            } else {
              setOpenImageViewer(true)
              setOpenPDFViewer(false)
            }

          } else {
            setDocumentViewerData(null)
            openSnackBar(respData.msg);
          }
        });
      }

    } else {
      let file = lorActualData[id].id_proof
      let extension = file.substring(file.indexOf("/") + 1, file.indexOf(";base64"));
      if (extension == "pdf") {
        console.log(file)
        setDocumentViewerData(file)
        setOpenPDFViewer(true)
      } else {
        setDocumentViewerData(file)
        setOpenImageViewer(true)
      }
    }
  }
  const uploadCorpRegForm = async (images) => {
    if (companyID !== undefined) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            let data = {
              "compid": companyID,
              "docType": "BUSINESS_REGISTRATION_FORM",
              "doc": result
            }
            setCorpRegFormData(images[0])
            setCorpRegForm(2);
            axios.post(`${config.url().baseURL}/add-corp-doc`, data)
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  setCorpRegForm(3);
                } else {
                  setCorpRegForm(1);
                  openSnackBar(respData.msg);
                }
              })
              .catch((err) => {
                setCorpRegBtnClr("primary");
                setCorpRegForm(1);
                openSnackBar("Unable to process.");
              });
          });
        } else {
          setCorpRegBtnClr("primary");
          setCorpRegForm(1);
          openSnackBar("File size is too large.");
        }
      } else {
        setCorpRegBtnClr("primary");
        setCorpRegForm(1);
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      openSnackBar("Company ID is not available");
    }
  };

  const uploadCorpAgreement = async (images) => {
    if (companyID !== undefined) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            let data = {
              "compid": companyID,
              "docType": "UPLOAD_CORPORATE_AGREEMENT_SIGNED",
              "doc": result
            }
            setCorpAgreementData(images[0])
            setCorpAgreement(2);
            axios.post(`${config.url().baseURL}/add-corp-doc`, data)
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  setCorpAgreement(3);
                } else {
                  setCorpAgreement(1);
                  openSnackBar(respData.msg);
                }
              })
              .catch((err) => {
                setCorpAgreementBtnClr("primary");
                setCorpAgreement(1);
                openSnackBar("Unable to process.");
              });
          });
        } else {
          setCorpAgreementBtnClr("primary");
          setCorpAgreement(1);
          openSnackBar("File size is too large.");
        }
      } else {
        setCorpAgreementBtnClr("primary");
        setCorpAgreement(1);
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      openSnackBar("Company ID is not available");
    }
  };

  const uploadComRegDoc = async (images) => {
    if (companyID !== undefined) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            let data = {
              "compid": companyID,
              "docType": "VALID_COMMERCIAL_REGISTRATION",
              "doc": result
            }
            setCommercialRegDoc(2);
            setCommercialRegDocData(images[0])
            axios.post(`${config.url().baseURL}/add-corp-doc`, data)
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  setCommercialRegDoc(3);
                } else {
                  setCommercialRegDoc(1);
                  openSnackBar(respData.msg);
                }
              })
              .catch((err) => {
                setComRegBtnClr("primary");
                setCommercialRegDoc(1);
                openSnackBar("Unable to process.");
              });
          });
        } else {
          setComRegBtnClr("primary");
          setCommercialRegDoc(1);
          openSnackBar("File size is too large.");
        }
      } else {
        setComRegBtnClr("primary");
        setCommercialRegDoc(1);
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      openSnackBar("Company ID is not available");
    }
  };

  const uploadRegulatorsLicense = async (images) => {
    if (companyID !== undefined) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            let data = {
              "compid": companyID,
              "docType": "REGULATOR_LICENSE",
              "doc": result
            }
            setRegulatorsLicense(2);
            setRegulatorsLicenseData(images[0])
            axios.post(`${config.url().baseURL}/add-corp-doc`, data)
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  setRegulatorsLicense(3);
                } else {
                  setRegulatorsLicense(1);
                  openSnackBar(respData.msg);
                }
              })
              .catch((err) => {
                setRegulatorsLicense(1);
                openSnackBar("Unable to process.");
              });
          });
        } else {
          setRegulatorsLicense(1);
          openSnackBar("File size is too large.");
        }
      } else {
        setRegulatorsLicense(1);
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      openSnackBar("Company ID is not available");
    }
  };

  const uploadMemorandum = async (images) => {
    if (companyID !== undefined) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            let data = {
              "compid": companyID,
              "docType": "MEMORANUM_ARTICLES_OF_INCORPORATION",
              "doc": result
            }
            setMemorandumData(images[0])
            setMemorandum(2);
            axios.post(`${config.url().baseURL}/add-corp-doc`, data)
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  setMemorandum(3);
                } else {
                  setMemorandum(1);
                  openSnackBar(respData.msg);
                }
              })
              .catch((err) => {
                setMemorandum(1);
                openSnackBar("Unable to process.");
              });
          });

        } else {
          setMemorandum(1);
          openSnackBar("File size is too large.");
        }
      } else {
        setMemorandum(1);
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      openSnackBar("Company ID is not available");
    }
  };

  const uploadFinancialRep = async (images) => {
    if (companyID !== undefined) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            let data = {
              "compid": companyID,
              "docType": "AUDITED_FINANCIALS",
              "doc": result
            }
            setFinancialRepData(images[0])
            setFinancialRep(2);
            axios.post(`${config.url().baseURL}/add-corp-doc`, data)
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  setFinancialRep(3);
                } else {
                  setFinancialRep(1);
                  openSnackBar(respData.msg);
                }
              })
              .catch((err) => {
                setFinancialRep(1);
                setFinRepBtnClr("primary");
                openSnackBar("Unable to process.");
              });
          });

        } else {
          setFinancialRep(1);
          setFinRepBtnClr("primary");
          openSnackBar("File size is too large.");
        }
      } else {
        setFinancialRep(1);
        setFinRepBtnClr("primary");
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      openSnackBar("Company ID is not available");
    }
  };

  const uploadOwnership = async (images) => {
    if (companyID !== undefined) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {

          getBase64(images[0], (result) => {
            let data = {
              "compid": companyID,
              "docType": "OWNERSHIP_STRUCTURE",
              "doc": result
            }
            setOwnerShip(2);
            setOwnerShipData(images[0])
            axios.post(`${config.url().baseURL}/add-corp-doc`, data)
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  setOwnerShip(3);
                } else {
                  setOwnerShip(1);
                  openSnackBar(respData.msg);
                }
              })
              .catch((err) => {
                setOwnerShip(1);
                openSnackBar("Unable to process.");
              });
          });
        } else {
          setOwnerShip(1);
          openSnackBar("File size is too large.");
        }
      } else {
        setOwnerShip(1);
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      openSnackBar("Company ID is not available");
    }
  };

  const uploadOtherDocument = async (images) => {
    if (companyID !== undefined) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            let data = {
              "compid": companyID,
              "docType": "VAT_CERTIFICATE",
              "doc": result
            }
            setVatCertificate(2);
            setVatCertificateData(images[0])
            axios.post(`${config.url().baseURL}/add-corp-doc`, data)
              .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                  setVatCertificate(3);
                } else {
                  setVatCertificate(1);
                  openSnackBar(respData.msg);
                }
              })
              .catch((err) => {
                setVatCertificate(1);
                openSnackBar("Unable to process.");
              });
          });
        } else {
          setVatCertificate(1);
          openSnackBar("File size is too large.");
        }
      } else {
        setVatCertificate(1);
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      openSnackBar("Company ID is not available");
    }
  };


  const verifyEmail = (value) => {
    // function that returns true if value is email, false otherwise
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyLength = (value, length) => {
    // function that verifies if a string has a given length or not
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verify = (value, type, errVariable, length) => {
    setUpdated(true)
    switch (type) {
      case "email":
        if (verifyEmail(value)) {
          setValues({ ...values, [errVariable]: false });
        } else {
          setValues({ ...values, [errVariable]: true });
        }
        break;
      case "length":
        if (verifyLength(value, length)) {
          setValues({ ...values, [errVariable]: false });
        } else {
          setValues({ ...values, [errVariable]: true });
        }
        break;
      case "cpr":
        if (value.length <= 9) {
          const re = /^[0-9\b]+$/;
          if (value === "" || re.test(value)) {
            if (verifyLength(value, length)) {
              setLorCPR(value);
              setValues({ ...values, [errVariable]: false });
            } else {
              setLorCPR(value);
              setValues({ ...values, [errVariable]: true });
            }
          }
        }
        break;
      default:
        break;
    }
  };

  const deleteServiceType = (value) => {
    let index = serviceTypes.findIndex((service) => service.value === value);
    let tempArray = serviceTypes;
    tempArray.splice(index, 1);
    setServiceTypes(tempArray);
    //function to delete service type from array
  };

  const cancelAddingService = () => {
    //function to cancel adding service
    setShowServiceAdd(false);
    setServiceType("");
    setNoOfEmployees("");
    setAvgTotalSalary("");
  };

  const validateService = () => {
    //function to validate service
    let flag = true, snackBarMsg = "";
    let serviceTypeErr = false,
      noOfEmployeesErr = false,
      avgTotalSalaryErr = false;

    if (!verifyLength(serviceType, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter select type." : snackBarMsg);
      serviceTypeErr = true;
      flag = false;
    }
    if (!verifyLength(noOfEmployees, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter number of employees." : snackBarMsg);
      noOfEmployeesErr = true;
      flag = false;
    }
    if (!verifyLength(avgTotalSalary, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter average total salary amount." : snackBarMsg);
      avgTotalSalaryErr = true;
      flag = false;
    } if (!flag) {
      openSnackBar(snackBarMsg)
    }

    setValues({
      ...values,
      serviceTypeErr: serviceTypeErr,
      noOfEmployeesErr: noOfEmployeesErr,
      avgTotalSalaryErr: avgTotalSalaryErr,
    });
    return flag;
  };

  const deleteService = (id) => {
    //function to delete a Publicly exposed person from array
    let tempArray = servicesData;
    let deletingService = servicesData[id].type_of_service;
    let tempServices = serviceTypes;
    tempServices.push({
      name: deletingService,
      value: deletingService,
    });

    tempArray.splice(id, 1);
    if (tempArray.length === 0) {
      setShowServiceAdd(true);
    }
    setServiceTypes(tempServices);
    setServicesData(tempArray);
    serviceToArray(tempArray);
  };

  const serviceToArray = (data) => {
    //function to convert Publicly exposed person json to array
    let temp = [];
    data.map((service, key) => {
      temp.push([
        key + 1,
        service.type_of_service,
        service.no_of_employee,
        service.average_total_salary,
        <Tooltip placement="right" title="Delete">
          <IconButton
            onClick={() => deleteService(key)}
            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
            aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>,
      ]);

      return 0;
    });
    setServicesTableData(temp);
  };

  const addService = () => {
    //function to add service
    if (validateService()) {
      let tempJson = servicesData;
      tempJson.push({
        type_of_service: serviceType,
        no_of_employee: noOfEmployees,
        average_total_salary: avgTotalSalary,
      });
      setServicesData(tempJson);
      serviceToArray(tempJson);
      deleteServiceType(serviceType);
      cancelAddingService();
    }/*  else {
      openSnackBar("Please fill all service details");
    } */
  };

  const validateForm = () => {
    //function to validate all form data
    let flag = true, snackBarMsg = "";
    let businessNatureErr = false,
      transactionActivityErr = false,
      declarationNameErr = false,
      declarationDesignationErr = false,
      authorizationErr = false,
      authorizationThirdPartyErr = false,
      assistedOfficerErr = false,
      bdoNameErr = false,
      bdoEmailErr = false,
      bdoIDErr = false,
      proposedSalaryDateErr = false,
      serviceFeeTypeErr = false,
      serviceFeeErr = false,
      bankNameErr = false,
      accountNameErr = false,
      accountNumberErr = false;


    if (!verifyLength(businessNature, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter business nature." : snackBarMsg);
      businessNatureErr = true;
      flag = false;
    }
    if (!verifyLength(transactionActivity, 1)) {
      transactionActivityErr = true;
      flag = false;
    }
    if (!verifyLength(declarationName, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter declaration name." : snackBarMsg);
      declarationNameErr = true;
      flag = false;
    }
    if (!verifyLength(declarationDesignation, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter declaration designation." : snackBarMsg);
      declarationDesignationErr = true;
      flag = false;
    }
    if (!verifyLength(authorization, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please choose authorization and confirmation." : snackBarMsg);
      authorizationErr = true;
      flag = false;
    }

    if (authorization === "false") {
      if (authorizationThirdParty == undefined || !verifyLength(authorizationThirdParty, 2)) {
        snackBarMsg = (snackBarMsg == "" ? "Please enter third party name." : snackBarMsg);
        authorizationThirdPartyErr = true;
        flag = false;
      }
    }
    if (!lorData[0].dob) {
      snackBarMsg = (snackBarMsg == "" ? "Please select DOB of Lor" : snackBarMsg);
      // lorDobErr = true;
      flag = false;
    }

    if (!verifyLength(assistedOfficer, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please select if you have the assistance of any BFC Officer or Not." : snackBarMsg);
      assistedOfficerErr = true;
      flag = false;
    }
    if (assistedOfficer === "true") {
      if (!verifyLength(bdoName, 2)) {
        snackBarMsg = (snackBarMsg == "" ? "Please enter business development officer name." : snackBarMsg);
        bdoNameErr = true;
        flag = false;
      }
      if (!verifyLength(bdoEmail, 2)) {
        snackBarMsg = (snackBarMsg == "" ? "Please enter business development officer email." : snackBarMsg);
        bdoEmailErr = true;
        flag = false;
      }
      if (!verifyLength(bdoEmpID, 1)) {
        snackBarMsg = (snackBarMsg == "" ? "Please enter business development officer ID." : snackBarMsg);
        bdoIDErr = true;
        flag = false;
      }
    }

    if (!verifyLength(proposedSalaryDate, 2)) {
      proposedSalaryDateErr = true;
      flag = false;
    }
    if (commercialRegDoc !== 3) {
      snackBarMsg = (snackBarMsg == "" ? "Please upload jpg,jpeg,png and pdf files." : snackBarMsg);
      setComRegBtnClr("danger");
      flag = false;
    }
    if (!verifyLength(serviceFeeType, 1)) {
      snackBarMsg = (snackBarMsg == "" ? "Please choose fee type." : snackBarMsg);
      serviceFeeTypeErr = true;
      flag = false;
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    }
    if (commercialRegDoc !== 3) {
      setCorpAgreementBtnClr("danger");
      flag = false;
    }
    // if(!validateLor()){
    //   flag=false
    // }
    // if (memorandum !== 3) {
    //   setMemorandumBtnClr("danger");
    //   flag = false;
    // }
    // if (financialRep !== 3) {
    //   setFinRepBtnClr("danger");
    //   flag = false;
    // }
    // if (ownership !== 3) {
    //   setOwnershipBtnClr("danger");
    //   flag = false;
    // }
    // if (regulatorsLicense !== 3) {
    //   setRegulatorsLicensepBtnClr("danger");
    //   flag = false;
    // }


    setValues({
      ...values,
      serviceFeeTypeErr: serviceFeeTypeErr,
      businessNatureErr: businessNatureErr,
      transactionActivityErr: transactionActivityErr,
      declarationNameErr: declarationNameErr,
      declarationDesignationErr: declarationDesignationErr,
      authorizationErr: authorizationErr,
      authorizationThirdPartyErr: authorizationThirdPartyErr,

      assistedOfficerErr: assistedOfficerErr,
      bdoNameErr: bdoNameErr,
      bdoEmailErr: bdoEmailErr,
      bdoIDErr: bdoIDErr,
      proposedSalaryDateErr: proposedSalaryDateErr,
      serviceFeeErr: serviceFeeErr
    });
    console.log(JSON.stringify(values));
    console.log(flag);
    return flag;
  };

  const verifyCorpData = () => {
    //function to validate corporate data
    let flag = true, snackBarMsg = "";
    let serviceFeeErr = false,
      bankNameErr = false,
      accountNameErr = false,
      accountNumberErr = false;

    if (serviceFeeType != "no fee") {
      if (!verifyLength(serviceFee, 1)) {
        // alert(serviceFee)
        snackBarMsg = (snackBarMsg == "" ? "Please enter service fee ." : snackBarMsg);
        serviceFeeErr = true;
        flag = false;
      }
    }
    if (!verifyLength(bankName, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter bank name ." : snackBarMsg);
      bankNameErr = true;
      flag = false;
    }
    if (!verifyLength(accountName, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter account name ." : snackBarMsg);
      accountNameErr = true;
      flag = false;
    }
    if (!verifyLength(accountNumber, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter IBAN number." : snackBarMsg);
      accountNumberErr = true;
      flag = false;
    }
    if (corpAgreement !== 3) {
      snackBarMsg = (snackBarMsg == "" ? "Please upload jpg,jpeg,png and pdf files." : snackBarMsg);
      setCorpAgreementBtnClr("danger");
      flag = false;
    }
    if (corpRegForm !== 3) {
      snackBarMsg = (snackBarMsg == "" ? "Please upload jpg,jpeg,png and pdf files." : snackBarMsg);
      setCorpRegBtnClr("danger");
      flag = false;
    }
    if (serviceFeeErr == true || bankNameErr == true || accountNameErr == true || accountNumberErr == true || values.serviceFeeErr == true || values.bankNameErr == true || values.accountNameErr == true || values.accountNumberErr == true) {
      flag = false
    }
    if (!flag) {
      openSnackBar(snackBarMsg)
    }
    setValues({
      ...values,
      serviceFeeErr: serviceFeeErr,
      bankNameErr: bankNameErr,
      accountNameErr: accountNameErr,
      accountNumberErr: accountNumberErr,
    });
    return flag;
  };



  const submitData = () => {
    if (companyID !== undefined) {
      // if (true) {
      setSubmitting(true);
      if (submitBtnLabel == "SAVE") {
        if (props.cacheEdit) {
          if (validateForm() && (m2pWalletCreate ? m2pWalletCreate && validateLor() : true)) {
            if (verifyCorpData()) {
              let data = {
                status: "updated",
                user: user._id,
                description: businessNature,
                transaction_activity: transactionActivity,
                services: servicesData,
                declaration: {
                  name: declarationName,
                  designation: declarationDesignation,
                },
                authorisation_confirmation: {
                  acting_behalf: authorization,
                  third_party_name: authorizationThirdParty,
                },
                letter_of_representation: lorActualData,
                assisted_officers: {
                  status: assistedOfficer,
                  officer_name: bdoName,
                  officer_email: bdoEmail,
                  officer_emp_id: bdoEmpID
                },
                proposed_date_of_first_salary_transfer: proposedSalaryDate,
                corporate_details: {
                  fee_type: serviceFeeType,
                  service_fee: serviceFee,
                  bank_name: bankName,
                  account_number: accountNumber,
                  account_name: accountName,
                }
              };
              axios.post(`${config.url().baseURL}/update-step-three-details/${companyID}`, data)
                .then((resp) => {
                  let respData = resp.data;
                  if (!respData.err) {
                    setShowCorpDetails(true);
                    setSubmitting(false);
                  } else {
                    openSnackBar(respData.msg);
                    setSubmitting(false);
                  }
                })
                .catch((err) => {
                  setSubmitting(false);
                  openSnackBar("Unable to process.");
                });
            } else {
              setSubmitting(false);
            }
          } else {
            setSubmitting(false);
          }
        } else {
          if (servicesData.length !== 0) {
            if (lorActualData.length !== 0) {
              if (validateForm() && (m2pWalletCreate ? m2pWalletCreate && validateLor() : true)) {
                if (props.edit) {
                  if (verifyCorpData()) {
                    let editTempData = editData
                    delete editTempData.CreatedBy
                    delete editTempData.CreatedDate
                    delete editTempData.Status
                    delete editTempData.SearchFilter
                    delete editTempData.TicketID
                    delete editTempData.Document
                    delete editTempData.Role
                    if (authorization == "true" || authorization == true) {
                      editTempData.AuthorisationAndConfirmation = true
                    } else {
                      editTempData.AuthorisationAndConfirmation = false
                      editTempData.NameOfThirdParty = authorizationThirdParty
                    }
                    if (props.approvedCorp !== undefined && props.approvedCorp) {
                      editTempData.edit = true
                    } else {
                      editTempData.edit = false
                    }
                    editTempData.user = user._id
                    // corprate portal m2p valid data
                    editTempData.m2pWalletCreate = m2pWalletCreate
                    editTempData.m2pWalletStatus = m2pWalletStatus
                    editTempData.m2pLegalRepCpr = m2pLegalRepCpr
                    console.log({ editTempData })
                    axios.post(`${config.url().baseURL}/kyc-update-customer-three/${companyID}`, editTempData, {
                      headers: {
                        auth: sessionStorage.getItem("cx_id"),
                      },
                    })
                      .then((resp) => {
                        let respData = resp.data;
                        if (!respData.err) {
                          setSubmitting(false);
                          setRespAlert(
                            <SweetAlert
                              success
                              style={{ display: "block" }}
                              title="Corporate update successful."
                              onConfirm={() => {
                                props.reset();
                                setRespAlert(null);
                              }}
                              confirmBtnCssClass={classes.button + " " + classes.success}
                            />
                          );
                        } else {
                          openSnackBar(respData.msg);
                          setSubmitting(false);
                        }
                      })
                      .catch((err) => {
                        setSubmitting(false);
                        // alert(err)
                        openSnackBar("Unable to process.");
                      });
                  }
                } else {
                  let data = {
                    user: user._id,
                    description: businessNature,
                    transaction_activity: transactionActivity,
                    services: servicesData,
                    declaration: {
                      name: declarationName,
                      designation: declarationDesignation,
                    },
                    authorisation_confirmation: {
                      acting_behalf: authorization,
                      third_party_name: authorizationThirdParty,
                    },
                    letter_of_representation: lorActualData,
                    assisted_officers: {
                      status: assistedOfficer,
                      officer_name: bdoName,
                      officer_email: bdoEmail,
                      officer_emp_id: bdoEmpID
                    },
                    proposed_date_of_first_salary_transfer: proposedSalaryDate,
                  };
                  axios.post(`${config.url().baseURL}/create-business-activity/${companyID}`, data, {
                    headers: {
                      auth: sessionStorage.getItem("cx_id"),
                    },
                  })
                    .then((resp) => {
                      let respData = resp.data;
                      if (!respData.err) {
                        setShowCorpDetails(true);
                        setSubmitting(false);
                        setSubmitBtnLabel("Complete");
                      } else {
                        openSnackBar(respData.msg);
                        setSubmitting(false);
                      }
                    })
                    .catch((err) => {
                      setSubmitting(false);
                      openSnackBar("Unable to process.");
                    });
                }
              } else {
                /*  openSnackBar("Empty or Incorrect data entered."); */
                setSubmitting(false);
              }
            } else {
              setSubmitting(false);
              openSnackBar("Please add minimum one L O R Person.");
            }
          } else {
            setSubmitting(false);
            openSnackBar("Please add minimum one service.");
          }
        }
      } else {
        if (verifyCorpData()) {
          let data = {
            fee_type: serviceFeeType,
            service_fee: serviceFee,
            bank_name: bankName,
            account_number: accountNumber,
            account_name: accountName,
          };
          setSaved(true)
          axios.post(`${config.url().baseURL}/corporate-bank-details/${companyID}`, data, {
            headers: {
              auth: sessionStorage.getItem("cx_id"),
            },
          })
            .then((resp) => {
              let respData = resp.data;
              if (!respData.err) {
                setSubmitting(false);
                setRespAlert(
                  <SweetAlert
                    success
                    style={{ display: "block" }}
                    title={respData.msg}
                    onConfirm={() => {
                      props.reset();
                      setRespAlert(null);
                    }}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                  />
                );
                // axios.post(`${config.url().baseURL}/kyc-create-company/${companyID}`,
                //   { creator: userGmail },
                //   {
                //     headers: {
                //       auth: sessionStorage.getItem("cx_id"),
                //     },
                //   }
                // ).then((resp) => {
                //   let respData = resp.data;
                //   if (!respData.err) {
                //     console.log("company kyc done")
                //   }
                // });
              } else {
                openSnackBar(respData.msg);
                setSubmitting(false);
              }
            })
            .catch((err) => {
              setSubmitting(false);
              openSnackBar("Unable to process.");
            });
        } else {
          setSubmitting(false);
          /*   openSnackBar("Empty or Incorrect data entered."); */
        }
      }
    } else {
      setSubmitting(false);
      openSnackBar("Company ID is not available");
    }
  };

  const downloadBusinessRegForm = () => {
    //function to download business registration form
    if (companyID !== undefined) {
      axios
        .get(`${config.url().baseURL}/download-corporate-aggrement-form/${companyID}`, {
          responseType: "blob",
        })
        .then(async (resp) => {
          download(resp.data, "RegistrationForm.pdf");
        })
        .catch((err) => {
          console.log(err);
          openSnackBar("Unable tp process.");
        });
    } else {
      openSnackBar("Company ID is not available");
    }
  };

  const downloadAgreement = () => {
    //function to download agreement
    axios
      .get(`${config.url().baseURL}/download-business-reg-form`, {
        responseType: "blob",
      })
      .then(async (resp) => {
        download(resp.data, "Agreement.pdf");
      })
      .catch((err) => {
        console.log(err);
        openSnackBar("Unable tp process.");
      });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };


  const uploadLorID = async (images) => {
    let lorDataNew = businessData.LetterOfRepresentation
    if (editFlag.status) {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            delete lorDataNew[editFlag.id].DocumentID
            lorDataNew[editFlag.id].CPRCopy = result
            setLorIDProof(result);
          });
          setLorUpButtonColor("secondary");
          setLorIDProofFileName(images[0].name);
        } else {
          openSnackBar("File size is too large.");
        }
      } else {
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    } else {
      if (/\.(jpe?g|png|gif|pdf)$/i.test(images[0].name) === true) {
        if (images[0].size < 4194304) {
          getBase64(images[0], (result) => {
            setLorIDProof(result);
          });
          setLorUpButtonColor("secondary");
          setLorIDProofFileName(images[0].name);
        } else {
          openSnackBar("File size is too large.");
        }
      } else {
        openSnackBar("Please upload jpg,jpeg,png and pdf files");
      }
    }
  };


  const validateLor = () => {
    //function to validate contact
    let flag = true, snackBarMsg = "";
    let lorNameErr = false, lorDesignationErr = false, lorCPRErr = false, lorEmailErr = false, lorMobileErr = false, lorLandlineErr = false, lorTitleErr = false,
      lorDobErr = false, lorGenderErr = false;
    const hasSpaces = /\s/.test(lorName || lorActualData[0].name);

    if (!verifyLength(lorName || lorActualData[0].name, 2) || !hasSpaces) {
      if (!verifyLength(lorName || lorActualData[0].name, 2)) {
        snackBarMsg = (snackBarMsg == "" ? "Please enter lor full name." : snackBarMsg);
      }
      if (!hasSpaces) {
        snackBarMsg = (snackBarMsg == "" ? "Full name should contain spaces." : snackBarMsg);
      }
      lorNameErr = true;
      flag = false;
    }
    if (!verifyLength(lorDesignation || lorActualData[0].designation, 2)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter lor designation." : snackBarMsg);
      lorDesignationErr = true;
      flag = false;
    }
    if (!verifyLength(lorCPR || lorActualData[0].cpr, 9)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter lor cpr." : snackBarMsg);
      lorCPRErr = true;
      flag = false;
    }
    if (!verifyEmail(lorEmail || lorActualData[0].email)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter lor email." : snackBarMsg);
      lorEmailErr = true;
      flag = false;
    }
    if (!verifyLength(lorMobile || lorActualData[0].mobile, 8)) {
      snackBarMsg = (snackBarMsg == "" ? "Please enter lor mobile." : snackBarMsg);
      lorMobileErr = true;
      flag = false;
    }

    // if (!verifyLength(lorIDProofFileName || lorActualData[0].id_proof, 1)) {
    //   snackBarMsg = (snackBarMsg == "" ? "Please enter lor id proof." : snackBarMsg);
    //   setLorUpButtonColor("danger")
    //   flag = false;
    // }

    if (!lorDob && !lorActualData[0].dob) {
      lorDobErr = true
      flag = false
      snackBarMsg = (snackBarMsg == "" ? "Please enter lor DOB." : snackBarMsg);
      setLorUpButtonColor("danger")
    }

    if (!lorTitle && !lorActualData[0].title) {
      lorTitleErr = true
      flag = false
      snackBarMsg = (snackBarMsg == "" ? "Please enter lor Title." : snackBarMsg);
      setLorUpButtonColor("danger")
    }

    if (!lorGender && !lorActualData[0].gender) {
      lorGenderErr = true
      flag = false
      snackBarMsg = (snackBarMsg == "" ? "Please enter lor Gender." : snackBarMsg);
      setLorUpButtonColor("danger")
    }

    // if (values.lorCPRErr) {
    //   lorCPRErr = true
    //   flag = false
    // }
    // if (values.lorMobileErr) {
    //   lorMobileErr = true
    //   flag = false
    // }

    // if (values.lorCPRErr) {
    //   lorCPRErr = true
    //   flag = false
    // }

    // if (values.lorEmailErr) {
    //   lorEmailErr = true
    //   flag = false
    // } 

    if (!flag) {
      openSnackBar(snackBarMsg)
    }

    setValues({
      ...values,
      lorNameErr: lorNameErr,
      lorDesignationErr: lorDesignationErr,
      lorCPRErr: lorCPRErr,
      lorEmailErr: lorEmailErr,
      lorMobileErr: lorMobileErr,
      lorLandlineErr: lorLandlineErr,
      lorGenderErr,
      lorDobErr,
      lorTitleErr
    });
    return flag;
  };

  const addLor = () => {
    //function to add lor
    if (validateLor()) {
      let tempJson = lorActualData;
      let tempTableJSON = lorData;
      tempJson.push({
        name: lorName,
        designation: lorDesignation,
        cpr: lorCPR,
        gender: lorGender,
        dob: lorDob,
        title: lorTitle,
        id_proof: lorIDProof,
        email: lorEmail,
        mobile: lorMobile,
        landline: lorLandLine,
        new: true
      });
      tempTableJSON.push({
        name: lorName,
        designation: lorDesignation,
        cpr: lorCPR,
        gender: lorGender,
        title: lorTitle,
        dob: lorDob,
        mobile: lorMobile,
        landline: lorLandLine,
        email: lorEmail,
        id_proof: lorIDProofFileName,
        new: true
      });
      let tempData = editData
      if (props.edit) {
        tempData.LetterOfRepresentation.push({
          "Name": lorName,
          "Designation": lorDesignation,
          "CPRNumber": lorCPR,
          "CPRCopy": lorIDProof,
          "EmailAddress": lorEmail,
          "MobileNumber": lorMobile,
          "LandLine": lorLandLine,
          "GENDER": lorGender,
          "TITLE": lorTitle,
          "DOB": lorDob,

        })
        setEditData(tempData)
      }

      setLorActualData(tempJson);
      setLorData(tempTableJSON)
      lorToArray(tempTableJSON);

      setShowLorAdd(false);
      setLorName("");
      setLorDesignation("");
      setLorCPR("");
      setLorGender("");
      setLorDob("");
      setLorTitle("");

      setLorEmail("");
      setLorMobile("");
      setLorLandLine("");
      setLorIDProof("");
      setLorIDProofFileName("");
    } /* else {
      openSnackBar("Please fill all LOR details.");
    } */
  };

  const lorToArray = (data) => {
    //function to convert lor json to array
    let temp = [];

    data.map((lor, key) => {
      console.log("key", key, selectedRow)
      // alert(lor.landline)
      temp.push([
        key + 1,
        lor.name,
        lor.designation,
        lor.cpr,
        lor.mobile,
        lor.landline,
        lor.email,
        lor.id_proof,
        <>
          {
            lor.new == undefined ? (
              <Tooltip placement="bottom" title="Edit">
                <IconButton
                  onClick={() => editLOR(key)}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
                  aria-label="delete">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip placement="right" title="Delete">
                <IconButton
                  onClick={() => deleteLor(key)}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                  aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )
          }
          <Tooltip placement="bottom" title="View Document">
            <IconButton
              onClick={() => idViewer(key)}
              style={{ marginTop: "-10px", marginBottom: "-10px", color: "#014FA4" }}
              aria-label="delete">
              <VisibilityIcon />
            </IconButton>
          </Tooltip>

        </>
        ,
      ]);
      return 0;
    });
    setLorTableData(temp);
    setLorTableKey(lorTableKey + 1)
  };

  const handleSelectRow = (key) => {
    setSelectedRow(prevSelectedRow => prevSelectedRow === key ? null : key); // Toggle selection or unselect
  };


  const deleteLor = (id) => {
    let temLor = lorActualData;
    let tempJSONLor = lorData;
    temLor.splice(id, 1);
    tempJSONLor.splice(id, 1);
    if (tempJSONLor.length === 0) {
      setShowLorAdd(true);
    }
    setLorActualData(temLor);
    setLorData(tempJSONLor)
    lorToArray(tempJSONLor);
    if (props.edit) {
      let tempEditData = businessData
      let lorData = tempEditData.LetterOfRepresentation
      lorData.splice(id, 1)
      tempEditData.LetterOfRepresentation = lorData
      setEditData(tempEditData)
    }
  };
  // React.useEffect(() => {
  //   alert("lor data set")
  // }, [lorActualData]);


  const cancelAddLor = () => {
    //function to cancel adding lor
    setShowLorAdd(false);
    setLorName("");
    setLorDesignation("");
    setLorCPR("");
    setLorGender("");
    setLorDob("");
    setLorTitle("");
    setLorEmail("");
    setLorMobile("");
    setLorLandLine("");
    setLorIDProof("");
    setLorIDProofFileName("");
  };

  const findExistingCPR = (cpr) => {
    //function to find cpr is already added
    if (lorActualData.find(lor => lor.cpr == cpr) !== undefined) {
      openSnackBar("Person with same cpr exist")
      setValues({ ...values, lorCPRErr: true });
    }
  }

  const findExistingMobile = (mobile) => {
    //function to find cpr is already added
    if (lorActualData.find(lor => lor.mobile == mobile) !== undefined) {
      openSnackBar("Person with same mobile no exist")
      setValues({ ...values, lorMobileErr: true });
    }
  }
  const findExistingEmail = (email) => {
    //function to find cpr is already added
    if (lorActualData.find(lor => lor.email == email) !== undefined) {
      openSnackBar("Person with same email exist")
      setValues({ ...values, lorEmailErr: true });
    }
  }

  const deleteID = () => {
    //function to delete id
    setLorIDProof("")
    setLorIDProofFileName("")
  }

  const editLOR = (id) => {
    setEditFlag({ status: true, id: id })
    setLorEditID(id)
    let dataTemp = lorActualData
    setLorName(dataTemp[id].name)
    setLorDesignation(dataTemp[id].designation)
    setLorCPR(dataTemp[id].cpr)
    setLorGender(dataTemp[id].gender)
    setLorDob(dataTemp[id].dob)
    setLorTitle(dataTemp[id].title)

    setLorMobile(dataTemp[id].mobile)
    setLorLandLine(dataTemp[id].landline)
    setLorEmail(dataTemp[id].email)
    if (props.edit) {
      setLorIDProofFileName(lorData[id].id_proof)
    } else {
      setLorIDProofFileName(dataTemp[id].id_proof)
    }
    setShowEditLor(true)
  }

  const saveLor = (id) => {
    //function to save edit lor 
    if (validateLor()) {
      // alert(lorLandLine)
      let tempLorData = [...lorActualData]
      let tempLorTable = [...lorData]
      tempLorData[id].name = lorName
      tempLorData[id].designation = lorDesignation
      tempLorData[id].cpr = lorCPR
      tempLorData[id].gender = lorGender
      tempLorData[id].dob = lorDob
      tempLorData[id].title = lorTitle

      tempLorData[id].id_proof = lorIDProof
      tempLorData[id].email = lorEmail
      tempLorData[id].mobile = lorMobile
      tempLorData[id].landline = lorLandLine

      tempLorTable[id].name = lorName
      tempLorTable[id].designation = lorDesignation
      tempLorTable[id].cpr = lorCPR
      tempLorTable[id].gender = lorGender
      tempLorTable[id].dob = lorDob
      tempLorTable[id].title = lorTitle

      tempLorTable[id].id_proof = lorIDProofFileName
      tempLorTable[id].email = lorEmail
      tempLorTable[id].mobile = lorMobile
      tempLorTable[id].landline = lorLandLine

      if (props.edit) {
        let lorDataNew = businessData.LetterOfRepresentation
        lorDataNew[id].Name = lorName
        lorDataNew[id].Designation = lorDesignation
        lorDataNew[id].CPRNumber = lorCPR
        lorDataNew[id].EmailAddress = lorEmail
        lorDataNew[id].MobileNumber = lorMobile
        lorDataNew[id].LandLine = lorLandLine
        lorDataNew[id].title = lorTitle
        lorDataNew[id].gender = lorGender
        lorDataNew[id].dob = lorDob


        if (lorDataNew[id].DocumentID) {
          lorDataNew[id].DocumentID = lorDataNew[id].DocumentID
        }

        let newEditData = editData
        newEditData.LetterOfRepresentation = lorDataNew
        setEditData(newEditData)
      }
      setLorActualData(tempLorData);
      setLorData(tempLorTable)
      // alert(JSON.stringify(tempLorData))
      lorToArray(tempLorTable);

      setShowEditLor(false)
      cancelAddLor()
    }
  }

  const handleEditData = (keyOne, keyTwo, keyThree, value) => {
    //function to handle edit data
    let data = editData
    if (props.edit) {
      if (keyThree !== null) {
        data[`${keyOne}`][`${keyTwo}`][`${keyThree}`] = value
      } else if (keyTwo !== null) {
        data[`${keyOne}`][`${keyTwo}`] = value
      } else {
        data[`${keyOne}`] = value
      }
      setEditData(data)
    }
  }

  const goBack = () => {
    //function to go back on corporate onboarding
    if (updated == true && saved == false) {
      setRespAlert(
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() => {
            props.previousStep(true, companyID)
          }}
          onCancel={() => {
            setRespAlert(null)
          }}
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          cancelBtnCssClass={classes.button}
          confirmBtnCssClass={classes.button + " " + classes.danger}
        >
          Do you really want to go back without saving complete data?
        </SweetAlert>)
    } else {
      props.previousStep(true, companyID)
    }
  }

  return (
    <div>
      {respAlert}
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>BUSINESS ACTIVITY PROFILE </h6>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                error={values.businessNatureErr}
                labelText={
                  <span>
                    Nature of Business <small className={classes.required}>*</small>
                  </span>
                }
                id="nature-of-business"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: businessNature,
                  disabled: props.edit,
                  onChange: (e) => {
                    setBusinessNature(libs.validate(e.target.value, "alphabet", 500) ? e.target.value : businessNature);
                    verify(libs.validate(e.target.value, "alphabet", 500) ? e.target.value : businessNature, "length", "businessNatureErr", 3);
                  },
                  onBlur: (e) => {
                    setBusinessNature(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <FormControl className={classes.selectFormControl} error={values.transactionActivityErr} fullWidth>
                <InputLabel className={classes.selectLabel}>
                  Source of Fund <small className={classes.required}>*</small>
                </InputLabel>
                <Select
                  id="entity-select"
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={transactionActivity}
                  //disabled={props.edit}
                  onChange={(e) => {
                    setTransactionActivity(e.target.value);
                    verify(e.target.value, "length", "transactionActivityErr", 3);
                  }}>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value="Sales">
                    Sales
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value="Marketing">
                    Marketing
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value="Service">
                    Service
                  </MenuItem>
                </Select>
              </FormControl>

            </GridItem>
            <GridItem xs={12} sm={12}>
              <p className={classes.sideHead}>Services</p>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <Divider style={{ background: "#474747" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={12} md={9}>
              <Collapse
                in={servicesTableData.length !== 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["#", "Type of Service", "No of Employees", "Average Total Salary", ""]}
                  tableData={servicesTableData}
                />
                {!showServiceAdd && serviceTypes.length !== 0 ? (
                  <>
                    <Button color="primary" size="lg" simple onClick={() => setShowServiceAdd(true)}>
                      Add Service
                    </Button>
                  </>
                ) : null}
              </Collapse>
            </GridItem>
            <GridItem xs={12} sm={12} />
            <Collapse
              in={showServiceAdd && serviceTypes.length !== 0}
              style={{ transformOrigin: "0 0 0", width: "100%", padding: "0px 15px" }}
              {...(true ? { timeout: 500 } : {})}>
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormControl className={classes.selectFormControl} error={values.serviceTypeErr} fullWidth>
                    <InputLabel className={classes.selectLabel}>
                      Type of Service <small className={classes.required}>*</small>
                    </InputLabel>
                    <Select
                      error={values.serviceTypeErr}
                      id="service-select"
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={serviceType}
                      onChange={(e) => {
                        setServiceType(e.target.value);
                        verify(e.target.value, "length", "serviceTypeErr", 3);
                      }}>
                      {serviceTypes.map((service) => {
                        return <MenuItem value={service.value}>{service.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    error={values.noOfEmployeesErr}
                    labelText={
                      <span>
                        No. Of Employees <small className={classes.required}>*</small>
                      </span>
                    }
                    id="no-of-employees"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      type: "text",
                      value: noOfEmployees,
                      onChange: (e) => {
                        if (e.target.value <= 100000) {
                          setNoOfEmployees(libs.validate(e.target.value, "number", 6) ? e.target.value : noOfEmployees);
                          verify(libs.validate(e.target.value, "number", 6) ? e.target.value : noOfEmployees, "length", "noOfEmployeesErr", 1);
                        }
                      },
                      onBlur: (e) => {
                        if (e.target.value > 0) {
                          setNoOfEmployees(e.target.value.trim());
                        } else {
                          setNoOfEmployees("")
                        }

                      }

                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    error={values.avgTotalSalaryErr}
                    labelText={
                      <span>
                        Average Total Salary Amount <small className={classes.required}>*</small>
                      </span>
                    }
                    id="avg-salary"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      type: "text",
                      value: avgTotalSalary,
                      onChange: (e) => {
                        const re = /^\d*\.?\d*$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setAvgTotalSalary(e.target.value);
                        }
                        verify(e.target.value, "length", "serviceFeeErr", 1);
                      },
                      onBlur: (e) => {
                        if (e.target.value !== "" && parseFloat(e.target.value) !== 0) {
                          let value = parseFloat(e.target.value.trim())
                          setAvgTotalSalary(value.toFixed(3));
                        } else {
                          setAvgTotalSalary("");
                        }
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  {servicesTableData.length !== 0 ? (
                    <>
                      <Button
                        color="primary"
                        className={classes.addActions}
                        size="sm"
                        simple
                        onClick={cancelAddingService}>
                        Cancel
                      </Button>
                    </>
                  ) : null}

                  <Button color="primary" className={classes.addActions} size="sm" onClick={addService}>
                    Add Service
                  </Button>
                </GridItem>
              </GridContainer>
            </Collapse>
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>Authorization and Confirmation, Declaration</h6>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={12} />
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.declarationNameErr}
                labelText={
                  <span>
                    Name <small className={classes.required}>*</small>
                  </span>
                }
                id="dec-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: declarationName,
                  disabled: props.edit,
                  onChange: (e) => {
                    setDeclarationName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : declarationName);
                    verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : declarationName, "length", "declarationNameErr", 3);
                  },
                  onBlur: (e) => {
                    setDeclarationName(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.declarationDesignationErr}
                labelText={
                  <span>
                    Designation <small className={classes.required}>*</small>
                  </span>
                }
                id="dec-designation"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: declarationDesignation,
                  disabled: props.edit,
                  onChange: (e) => {
                    setDeclarationDesignation(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : declarationDesignation);
                    verify(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : declarationDesignation, "length", "declarationDesignationErr", 3);
                  },
                  onBlur: (e) => {
                    setDeclarationDesignation(e.target.value.trim());
                  }
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={3}>
              {/* <CustomInput
                error={values.declarationDesignationErr}
                labelText={
                  <span>
                    selected lor <small className={classes.required}>*</small>
                  </span>
                }
                id="dec-designation"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: declarationDesignation,
                  disabled: props.edit,
                  onChange: (e) => {
                    setDeclarationDesignation(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : declarationDesignation);
                    verify(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : declarationDesignation, "length", "declarationDesignationErr", 3);
                  },
                  onBlur: (e) => {
                    setDeclarationDesignation(e.target.value.trim());
                  }
                }}
              /> */}
            </GridItem>
            {m2pWalletStatus !== "ACTIVE" && (
              <Grid item xs={12} sm={12} md={6}>
                <label style={{ color: 'black', fontSize: '18px', fontWeight: 'bold' }}>
                  Do you want to provide Corporate Cards to this Corporate?
                </label>
                <Switch
                  label="M2p"
                  checked={m2pWalletCreate}
                  onChange={handleCLickVate}
                />
              </Grid>
            )}
            {m2pWalletCreate && (
              <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'left', marginTop: '10px' }}>
                {/* <VisibilityIcon
                  style={{ cursor: 'pointer', color: "#014FA4"}}
                  onClick={handlePopupOpen}
                  titleAccess="View Legal Representatives"
                /> */}
              </Grid>
            )}
            <LegalRepsPopup
              open={(isPopupOpen&&m2pWalletCreate)&&(m2pWalletStatus !== "ACTIVE")}
              onClose={handlePopupClose}
              companyID={companyID}
              setM2pLegalRepCpr={setM2pLegalRepCpr}
              selectedCpr={businessData.m2pLegalRepCpr}

            />
            <GridItem xs={12} sm={12}>
              <p className={classes.question}>
                I confirm that I am acting on behalf of the company mentioned in this form{" "}
                <small className={classes.required}>*</small>
              </p>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormControl
                className={classes.radioGroup}
                error={values.authorizationErr}
                style={{ textAlign: "left !important" }}
                fullWidth
                component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  value={authorization}
                  disabled={props.edit}
                  onChange={(e) => {
                    setAuthorization(e.target.value);
                    verify(e.target.value, "length", "authorizationErr", 3);
                  }}
                  name="position">
                  <FormControlLabel
                    className={classes.statusText}
                    value="true"
                    control={<Radio color="primary" />}
                    label="Yes"
                  />

                  <FormControlLabel
                    className={classes.statusText}
                    value="false"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </GridItem>
            <Collapse
              in={authorization === "false"}
              style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
              {...(true ? { timeout: 500 } : {})}>
              <GridContainer>
                <GridItem xs={12} sm={9}>
                  <CustomInput
                    error={values.authorizationThirdPartyErr}
                    labelText={
                      <span>
                        Name of Third party <small className={classes.required}>*</small>
                      </span>
                    }
                    id="auth-third-party"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: authorizationThirdParty,
                      disabled: props.edit,
                      onChange: (e) => {
                        setAuthorizationThirdParty(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : authorizationThirdParty);
                        verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : authorizationThirdParty, "length", "authorizationThirdPartyErr", 3);
                        /* setAuthorizationThirdParty(e.target.value);
                        verify(e.target.value, "length", "authorizationThirdPartyErr", 1); */
                      },
                      onBlur: (e) => {
                        setAuthorizationThirdParty(e.target.value.trim());
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </Collapse>
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>Letter of Representation</h6>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Collapse
                in={lorTableData.length !== 0}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Table
                  key={lorTableKey}
                  tableHeaderColor="primary"
                  tableHead={["#", "Name", "Designation", "CPR", "Mobile", "Landline", "Email", "ID Proof", ""]}
                  tableData={lorTableData}
                />
                {!showLorAdd ? (
                  <>
                    {/* <Button color="primary" size="lg" simple onClick={() => setShowLorAdd(true)}>
                      Add
                    </Button> */}
                  </>
                ) : null}
              </Collapse>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            <Collapse
              in={showLorAdd}
              style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
              {...(true ? { timeout: 500 } : {})}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    error={values.lorNameErr}
                    labelText={
                      <span>
                        Full Name <small className={classes.required}>*</small>
                      </span>
                    }
                    id="lor-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: lorName,
                      onChange: (e) => {
                        setLorName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : lorName);
                        verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : lorName, "length", "lorNameErr", 3);
                        /*  setLorName(e.target.value);
                         verify(e.target.value, "length", "lorNameErr", 2); */
                      },
                      onBlur: (e) => {
                        setLorName(e.target.value.trim());
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    error={values.lorDesignationErr}
                    labelText={
                      <span>
                        Designation <small className={classes.required}>*</small>
                      </span>
                    }
                    id="lor-designation"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: lorDesignation,
                      onChange: (e) => {
                        setLorDesignation(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : lorDesignation);
                        verify(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : lorDesignation, "length", "lorDesignationErr", 3);
                        /*  setLorDesignation(e.target.value);
                         verify(e.target.value, "length", "lorDesignationErr", 2); */
                      },
                      onBlur: (e) => {
                        setLorDesignation(e.target.value.trim());
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    error={values.lorCPRErr}
                    labelText={
                      <span>
                        CPR <small className={classes.required}>*</small>
                      </span>
                    }
                    id="lor-cpr"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: lorCPR,
                      onChange: (e) => {
                        verify(e.target.value, "cpr", "lorCPRErr", 9);
                      },
                      onBlur: (e) => {
                        findExistingCPR(e.target.value)

                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    error={values.lorMobileErr}
                    labelText={
                      <span>
                        Mobile <small className={classes.required}>*</small>
                      </span>
                    }
                    id="lor-mobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: lorMobile,
                      onChange: (e) => {
                        const re = /^[0-9\b]+$/;
                        var res = e.target.value.substring(0, 1)
                        if (res == 3 || res == 6 || e.target.value == "") {
                          if (e.target.value === "" || re.test(e.target.value)) {
                            if (e.target.value.length <= 8) {
                              setLorMobile(e.target.value);
                            }
                          }
                        }
                        verify(e.target.value, "length", "lorMobileErr", 8);
                      },
                      onBlur: (e) => {
                        findExistingMobile(e.target.value)
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    error={values.lorLandlineErr}
                    labelText={
                      <span>
                        LandLine
                      </span>
                    }
                    id="lor-landline"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: lorLandLine,
                      onChange: (e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          if (e.target.value.length <= 8) {
                            setLorLandLine(e.target.value);
                          }
                        }
                        if (e.target.value.length == 0) {
                          setValues({ ...values, lorLandlineErr: false })
                        } else {
                          verify(e.target.value, "length", "lorLandlineErr", 8);
                        }
                      },
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={3}>

                  <FormControl className={classes.selectFormControl} fullWidth>
                    <InputLabel className={classes.dateLabel}>
                      <span>
                        Date of Birth <small className={classes.required}>*</small>
                      </span>
                    </InputLabel>
                    <TextField
                      error={values.lorDobErr}
                      className={classes.dateInput}
                      type="date"
                      inputProps={{
                        autoComplete: "off",
                        // disabled: props.edit,
                        type: "date",
                        max: moment(new Date()).format("YYYY-MM-DD"),
                        value: lorDob,
                        onChange: (e) => {
                          verify(e.target.value, "length", "lorDobErr", 3);
                          setLorDob(e.target.value);
                          // if (moment(e.target.value).isAfter(moment(new Date()))) {
                          //   setValues({ ...values, dateOfIncorporationErr: true })
                          //   openSnackBar("Please enter valid lorDob date.")
                          // } else {
                          //   handleEditData("IncorporationDetails", "DateOfIncorporation", null, e.target.value)
                          // }
                        },
                        onBlur: (e) => {
                          verify(e.target.value, "length", "dateOfIncorporationErr", 3);
                          if (moment(e.target.value).isAfter(moment(new Date()))) {
                            setValues({ ...values, dateOfIncorporationErr: true })
                          }
                        },
                      }}
                    />
                  </FormControl>

                  {/* <CustomInput
                // error={values.lorLandlineErr}
                error={values.lorDobErr}

                labelText={
                  <span>
                    DOB <small className={classes.required}>*</small>
                  </span>
                }
                id="lor-dob"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  type: "date",
                  value: lorDob,
                  max: new Date().toISOString().split("T")[0], // Ensure max is passed
                  onChange: (e) => {
                    // const re = /^[0-9\b]+$/;
                    // if (e.target.value === "" || re.test(e.target.value)) {
                    //   if (e.target.value.length <= 8) {
                    setLorDob(e.target.value);
                    //   }
                    // }
                    // if (e.target.value.length == 0) {
                    //   setValues({ ...values, lorLandlineErr: false })
                    // } else {
                    //   verify(e.target.value, "length", "lorLandlineErr", 8);
                    // }
                  },
                }}
              /> */}
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="lor-title">TITLE <small className={classes.required}>*</small></InputLabel>
                    <Select
                      id="lor-title"
                      value={lorTitle}
                      onChange={(e) => {
                        setLorTitle(e.target.value.trim());
                        verify(e.target.value, "length", "titleErr", 2);
                      }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                    >
                      <MenuItem value="" disabled>Select Title</MenuItem>
                      <MenuItem value="MR">Mr.</MenuItem>
                      <MenuItem value="MRS">Mrs.</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="lor-gender">GENDER</InputLabel>
                    <Select
                      id="lor-gender"
                      value={lorGender}
                      onChange={(e) => {
                        setLorGender(e.target.value.trim());
                        verify(e.target.value, "length", "titleErr", 2);
                      }}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                    >
                      <MenuItem value="" disabled>Select Gender</MenuItem>
                      <MenuItem value="M">Male</MenuItem>
                      <MenuItem value="F">Female</MenuItem>
                      <MenuItem value="O">Other</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    error={values.lorEmailErr}
                    labelText={
                      <span>
                        Email <small className={classes.required}>*</small>
                      </span>
                    }
                    id="lor-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: lorEmail,
                      onChange: (e) => {
                        setLorEmail(e.target.value);
                        verify(e.target.value, "email", "lorEmailErr", 3);
                      },
                      onBlur: (e) => {
                        setLorEmail(e.target.value.trim());
                        findExistingEmail(e.target.value)
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {lorIDProofFileName !== "" ? (
                    <CustomInput
                      labelText="Certified copy of identification"
                      id="dir-id-proof"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        readOnly: true,
                        value: lorIDProofFileName,
                      }}
                    />
                  ) : (
                    <>
                      <br /> <br />
                      <Dropzone onDrop={uploadLorID}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button size="sm" fullWidth color={lorUpButtonColor}>
                              ID Proof
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    </>
                  )}

                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {lorTableData.length !== 0 ? (
                    <>
                      <Button
                        color="primary"
                        className={classes.addOwnerActions}
                        size="sm"
                        simple
                        onClick={cancelAddLor}>
                        Cancel
                      </Button>
                    </>
                  ) : null}

                  <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={addLor}>
                    Add
                  </Button>
                </GridItem>
              </GridContainer>
            </Collapse>
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>Assisted officer details</h6>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <p className={classes.question}>
                Assisted by BFC Payments/BFC Employee? <small className={classes.required}>*</small>
              </p>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <FormControl
                className={classes.radioGroup}
                error={values.assistedOfficerErr}
                style={{ textAlign: "left !important" }}
                fullWidth
                component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  value={assistedOfficer}
                  disabled={props.edit}
                  onChange={(e) => {
                    setAssistedOfficer(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : lorDesignation);
                    verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : lorDesignation, "length", "assistedOfficerErr", 3);

                    /*  setAssistedOfficer(e.target.value);
                      verify(e.target.value, "length", "assistedOfficerErr", 3);*/
                  }}
                  name="position">
                  <FormControlLabel
                    className={classes.statusText}
                    value="true"
                    disabled={props.edit}
                    control={<Radio color="primary" />}
                    label="Yes"
                  />

                  <FormControlLabel
                    className={classes.statusText}
                    value="false"
                    disabled={props.edit}
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </GridItem>
            <Collapse
              in={assistedOfficer === "true"}
              style={{ transformOrigin: "0 0 0", textAlign: "center", width: "100%", padding: "0px 15px" }}
              {...(true ? { timeout: 500 } : {})}>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    error={values.bdoNameErr}
                    labelText={
                      <span>
                        Business Development Officer Name <small className={classes.required}>*</small>
                      </span>
                    }
                    id="bdo-name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: bdoName,
                      disabled: props.edit,
                      onChange: (e) => {
                        setBdoName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : bdoName);
                        verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : bdoName, "length", "bdoNameErr", 3);
                        /* setBdoName(e.target.value);
                        verify(e.target.value, "length", "bdoNameErr", 2); */
                      },
                      onBlur: (e) => {
                        setBdoName(e.target.value.trim());
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={5}>
                  <CustomInput
                    error={values.bdoEmailErr}
                    labelText={
                      <span>
                        Business Development Officer Email <small className={classes.required}>*</small>
                      </span>
                    }
                    id="bdo-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: bdoEmail,
                      disabled: props.edit,
                      onChange: (e) => {
                        setBdoEmail(e.target.value);
                        verify(e.target.value, "email", "bdoEmailErr", 2);
                      },
                      onBlur: (e) => {
                        setBdoEmail(e.target.value.trim());
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    error={values.bdoIDErr}
                    labelText={
                      <span>
                        Business Development Officer ID <small className={classes.required}>*</small>
                      </span>
                    }
                    id="bdo-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      autoComplete: "off",
                      value: bdoEmpID,
                      disabled: props.edit,
                      onChange: (e) => {
                        if (e.target.value.length <= 10) {
                          setBdoEmpID(e.target.value);
                        }
                        verify(e.target.value, "length", "bdoIDErr", 10);
                      },
                      onBlur: (e) => {
                        setBdoEmpID(e.target.value.trim());
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </Collapse>

            {/* <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>Proposed Date of First Salary Transfer</h6>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <FormControl className={classes.selectFormControl} fullWidth>
                <InputLabel className={classes.dateLabel}>
                  <span>
                    Date of First Salary Transfer <small className={classes.required}>*</small>
                  </span>
                </InputLabel>
                <TextField
                  error={values.proposedSalaryDateErr}
                  className={classes.dateInput}
                  type="date"
                  inputProps={{
                    autoComplete: "off",
                    type: "date",
                    min: moment(new Date()).format("YYYY-MM-DD"),
                    max: "9999-12-31",
                    value: proposedSalaryDate,
                    onChange: (e) => {
                      setProposedSalaryDate(e.target.value);
                      verify(e.target.value, "length", "proposedSalaryDateErr", 2);
                    },
                  }}
                />
              </FormControl>
            </GridItem> */}
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}>KYC Documents <small>(Max Upload size 4Mb)</small> </h6>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Table
                tableHeaderColor="primary"
                tableData={[
                  [
                    <h6 className={classes.docName}>Valid Commercial Registration <small className={classes.required}>*</small></h6>,
                    commercialRegDoc == 1 ? (
                      <Dropzone onDrop={uploadComRegDoc}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button className={classes.uploadButton} size="sm" fullWidth color={comRegBtnClr}>
                              Upload
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    ) : null,
                    commercialRegDoc == 2 ? (
                      <MoonLoader color="#004fa4" loading={true} css={override} size={25} />
                    ) : null,
                    commercialRegDoc == 3 ?
                      <>
                        <CheckCircleIcon style={{ color: "#004fa4" }} />
                        <Tooltip placement="bottom" title="View Document">
                          <IconButton
                            onClick={() => setDocumentViewer("commercialReg")}
                            style={{ marginTop: "-20px", color: "#014FA4" }}
                            aria-label="delete">
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        {!props.edit ? (
                          <Tooltip placement="bottom" title="Delete">
                            <IconButton
                              onClick={() => deleteDoc("commercialReg")}
                              style={{ marginTop: "-20px", color: "red" }}
                              aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </> : null,
                  ],
                  [
                    <h6 className={classes.docName}>
                      Regulator’s license <small>(if applicable)</small>
                    </h6>,
                    regulatorsLicense == 1 ? (
                      <Dropzone onDrop={uploadRegulatorsLicense}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button className={classes.uploadButton} size="sm" fullWidth color={regulatorsLicenseBtnClr}>
                              Upload
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    ) : null,
                    regulatorsLicense == 2 ? (
                      <MoonLoader color="#004fa4" loading={true} css={override} size={25} />
                    ) : null,
                    regulatorsLicense == 3 ?
                      <>
                        <CheckCircleIcon style={{ color: "#004fa4" }} />
                        <Tooltip placement="bottom" title="View Document">
                          <IconButton
                            onClick={() => setDocumentViewer("regulatorsLicense")}
                            style={{ marginTop: "-20px", color: "#014FA4" }}
                            aria-label="delete">
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        {props.edit !== true ? (
                          <Tooltip placement="bottom" title="Delete">
                            <IconButton
                              onClick={() => deleteDoc("regulatorsLicense")}
                              style={{ marginTop: "-20px", color: "red" }}
                              aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </> : null,
                  ],
                  [
                    <h6 className={classes.docName}>
                      Memorandum and articles of incorporation, including all amendments to original.{" "}
                      <small>(Non applicable for Sole Proprietorship)</small>{" "}
                    </h6>,
                    memorandum == 1 ? (
                      <Dropzone onDrop={uploadMemorandum}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button className={classes.uploadButton} size="sm" fullWidth color={memorandumBtnClr}>
                              Upload
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    ) : null,
                    memorandum == 2 ? <MoonLoader color="#004fa4" loading={true} css={override} size={25} /> : null,
                    memorandum == 3 ?
                      <>
                        <CheckCircleIcon style={{ color: "#004fa4" }} />
                        <Tooltip placement="bottom" title="View Document">
                          <IconButton
                            onClick={() => setDocumentViewer("memorandum")}
                            style={{ marginTop: "-20px", color: "#014FA4" }}
                            aria-label="delete">
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        {props.edit !== true ? (
                          <Tooltip placement="bottom" title="Delete">
                            <IconButton
                              onClick={() => deleteDoc("memorandum")}
                              style={{ marginTop: "-20px", color: "red" }}
                              aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}

                      </> : null,
                  ],
                  [
                    <h6 className={classes.docName}>Most recent audited financial report</h6>,
                    financialRep == 1 ? (
                      <Dropzone onDrop={uploadFinancialRep}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button className={classes.uploadButton} size="sm" fullWidth color={finRepBtnClr}>
                              Upload
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    ) : null,
                    financialRep == 2 ? <MoonLoader color="#004fa4" loading={true} css={override} size={25} /> : null,
                    financialRep == 3 ?
                      <>
                        <CheckCircleIcon style={{ color: "#004fa4" }} />
                        <Tooltip placement="bottom" title="View Document">
                          <IconButton
                            onClick={() => setDocumentViewer("financialRep")}
                            style={{ marginTop: "-20px", color: "#014FA4" }}
                            aria-label="delete">
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        {props.edit !== true ? (
                          <Tooltip placement="bottom" title="Delete">
                            <IconButton
                              onClick={() => deleteDoc("financialRep")}
                              style={{ marginTop: "-20px", color: "red" }}
                              aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </> : null,
                  ],
                  [
                    <h6 className={classes.docName}>
                      Ownership structure chart for multi-tiered ownership structures.{" "}
                      <small>(Non applicable for Sole Proprietorship)</small>
                    </h6>,
                    ownership == 1 ? (

                      <Dropzone onDrop={uploadOwnership}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button className={classes.uploadButton} size="sm" fullWidth color={ownershipBtnClr}>
                              Upload
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    ) : null,
                    ownership == 2 ? <MoonLoader color="#004fa4" loading={true} css={override} size={25} /> : null,
                    ownership == 3 ? <>
                      <CheckCircleIcon style={{ color: "#004fa4" }} />
                      <Tooltip placement="bottom" title="View Document">
                        <IconButton
                          onClick={() => setDocumentViewer("ownership")}
                          style={{ marginTop: "-20px", color: "#014FA4" }}
                          aria-label="delete">
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {props.edit !== true ? (
                        <Tooltip placement="bottom" title="Delete">
                          <IconButton
                            onClick={() => deleteDoc("ownership")}
                            style={{ marginTop: "-20px", color: "red" }}
                            aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </> : null,
                  ],
                  [
                    <h6 className={classes.docName}>
                      VAT CERTIFICATE
                    </h6>,
                    vatCertificate == 1 ? (

                      <Dropzone onDrop={uploadOtherDocument}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button className={classes.uploadButton} size="sm" fullWidth color={ownershipBtnClr}>
                              Upload
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                    ) : null,
                    vatCertificate == 2 ? <MoonLoader color="#004fa4" loading={true} css={override} size={25} /> : null,
                    vatCertificate == 3 ? <>
                      <CheckCircleIcon style={{ color: "#004fa4" }} />
                      <Tooltip placement="bottom" title="View Document">
                        <IconButton
                          onClick={() => setDocumentViewer("vat")}
                          style={{ marginTop: "-20px", color: "#014FA4" }}
                          aria-label="delete">
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {props.edit !== true ? (
                        <Tooltip placement="bottom" title="Delete">
                          <IconButton
                            onClick={() => deleteDoc("vat")}
                            style={{ marginTop: "-20px", color: "red" }}
                            aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </> : null,
                  ],

                ]}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <h6 className={classes.subHead}> Bank details</h6>
            </GridItem>

            <GridItem xs={12} sm={12}>
              <Divider style={{ background: "black" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <br />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <p className={classes.question}>
                Fee Type
                <small className={classes.required}>*</small>
              </p>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <FormControl
                className={classes.radioGroup}
                error={values.serviceFeeTypeErr}
                style={{ textAlign: "left !important" }}
                fullWidth
                component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  value={serviceFeeType}
                  onChange={(e) => {
                    setServiceFeeType(e.target.value);
                    verify(e.target.value, "length", "feeTypeErr", 3);
                    if (e.target.value == "no fee") {
                      setServiceFee(0)
                    } else setServiceFee("")
                    if (props.edit) {
                      handleEditData("FeeType", null, null, e.target.value)
                    }
                  }}
                  name="position">
                  <FormControlLabel
                    className={classes.statusText}
                    value="monthly"
                    control={<Radio color="primary" />}
                    label="Monthly Fee"
                  />

                  <FormControlLabel
                    className={classes.statusText}
                    value="transaction"
                    control={<Radio color="primary" />}
                    label="Per employee service Fee"
                  />
                  <FormControlLabel
                    className={classes.statusText}
                    value="no fee"
                    control={<Radio color="primary" />}
                    label="No Fee"
                  />
                </RadioGroup>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.serviceFeeErr}
                labelText={
                  <span>
                    Fee in BHD <small className={classes.required}>*</small>
                  </span>
                }
                id="service-fee"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: serviceFee,
                  disabled: serviceFeeType == "no fee",
                  onChange: (e) => {
                    const re = /^\d*\.?\d*$/;
                    if ((e.target.value === "" || re.test(e.target.value)) && e.target.value <= 1000000) {
                      if ((serviceFeeType !== "no fee" && e.target.value < 1) || e.target.value === "") {
                        setServiceFee(e.target.value);
                      } else {
                        setServiceFee(e.target.value);
                      }
                    }
                    verify(e.target.value, "length", "serviceFeeErr", 1);
                  },
                  onBlur: (e) => {
                    if (e.target.value !== "") {
                      let value = parseFloat(e.target.value.trim())
                      setServiceFee(value.toFixed(3));
                    }
                    handleEditData("BusinessRegistrationDetails", "ServiceFeeInBHD", null, e.target.value.trim())
                  }

                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.bankNameFeeErr}
                labelText={
                  <span>
                    Bank Name <small className={classes.required}>*</small>
                  </span>
                }
                id="bank-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: bankName,
                  onChange: (e) => {
                    setBankName(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : bankName);
                    verify(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : bankName, "length", "bankNameErr", 3);
                    /* setBankName(e.target.value);
                    verify(e.target.value, "length", "bankNameErr", 2); */
                  },
                  onBlur: (e) => {
                    handleEditData("BusinessRegistrationDetails", "BankName", null, e.target.value.trim())
                    setBankName(e.target.value.trim());
                  }

                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.accountNameErr}
                labelText={
                  <span>
                    Account Name <small className={classes.required}>*</small>
                  </span>
                }
                id="ac-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: accountName,
                  onChange: (e) => {
                    setAccountName(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : accountName);
                    verify(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : accountName, "length", "accountNameErr", 3);
                    /*  setAccountName(e.target.value);
                     verify(e.target.value, "length", "accountNameErr", 2); */
                  },
                  onBlur: (e) => {
                    handleEditData("BusinessRegistrationDetails", "AccountName", null, e.target.value.trim())
                    setAccountName(e.target.value.trim());
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.accountNumberErr}
                labelText={
                  <span>
                    IBAN number <small className={classes.required}>*</small>
                  </span>
                }
                id="ac-no"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: accountNumber,
                  onChange: (e) => {
                    const re = /^\w+$/;
                    if (e.target.value === "" || re.test(e.target.value.trim())) {
                      setAccountNumber(e.target.value);
                    }
                    verify(e.target.value, "length", "accountNumberErr", 2);
                  },
                  onBlur: (e) => {
                    handleEditData("BusinessRegistrationDetails", "AccountNumber", null, e.target.value.trim())
                    if (e.target.value.substring(0, 2) == "BH" && IBAN.isValid(e.target.value.trim())) {
                      setAccountNumber(e.target.value.trim());
                    } else {
                      openSnackBar("Please enter valid IBAN Number.")
                      setAccountNumber("")
                      setValues({ ...values, accountNumberErr: true })
                    }
                  }
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Collapse
                in={showCorpDetails}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <h6 className={classes.subHead}> Download Agreements</h6>
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <Divider style={{ background: "black" }} fullWidth />
                  </GridItem>
                  <GridItem xs={12} sm={3}>
                    <br />
                    <Button size="sm" onClick={downloadBusinessRegForm} color="primary" fullWidth>
                      Business Registration Form
                    </Button>
                  </GridItem>

                  <GridItem xs={12} sm={3}>
                    <br />
                    <Button onClick={downloadAgreement} size="sm" color="primary" fullWidth>
                      Corporate Agreement
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Table
                      tableHeaderColor="primary"
                      tableData={[
                        [
                          <h6 className={classes.docName}>
                            Corporate Business Registration Form <small>(signed and stamped)</small>
                          </h6>,
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          corpRegForm === 1 ? (
                            <Dropzone onDrop={uploadCorpRegForm}>
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <Button className={classes.uploadButton} size="sm" fullWidth color={corpRegBtnClr}>
                                    Upload
                                  </Button>
                                </div>
                              )}
                            </Dropzone>
                          ) : null,
                          corpRegForm === 2 ? (
                            <MoonLoader color="#004fa4" loading={true} css={override} size={25} />
                          ) : null,
                          corpRegForm === 3 ?
                            <>
                              <CheckCircleIcon style={{ color: "#004fa4" }} />
                              <Tooltip placement="bottom" title="View Document">
                                <IconButton
                                  onClick={() => setDocumentViewer("corpRegForm")}
                                  style={{ marginTop: "-20px", color: "#014FA4" }}
                                  aria-label="delete">
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                              {props.edit !== true ? (
                                <Tooltip placement="bottom" title="Delete">
                                  <IconButton
                                    onClick={() => deleteDoc("corpRegForm")}
                                    style={{ marginTop: "-20px", color: "red" }}
                                    aria-label="delete">
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                            </> : null,
                        ],
                        [
                          <h6 className={classes.docName}>Corporate Agreement</h6>,
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          "",
                          corpAgreement == 1 ? (
                            <Dropzone onDrop={uploadCorpAgreement}>
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <Button
                                    className={classes.uploadButton}
                                    size="sm"
                                    fullWidth
                                    color={corpAgreementBtnClr}>
                                    Upload
                                  </Button>
                                </div>
                              )}
                            </Dropzone>
                          ) : null,
                          corpAgreement == 2 ? (
                            <MoonLoader color="#004fa4" loading={true} css={override} size={25} />
                          ) : null,
                          corpAgreement == 3 ?
                            <>
                              <CheckCircleIcon style={{ color: "#004fa4" }} />
                              <Tooltip placement="bottom" title="View Document">
                                <IconButton
                                  onClick={() => setDocumentViewer("corpAgreement")}
                                  style={{ marginTop: "-20px", color: "#014FA4" }}
                                  aria-label="delete">
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                              {props.edit !== true ? (
                                <Tooltip placement="bottom" title="Delete">
                                  <IconButton
                                    onClick={() => deleteDoc("corpAgreement")}
                                    style={{ marginTop: "-20px", color: "red" }}
                                    aria-label="delete">
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                            </> : null,
                        ],

                      ]}
                    />
                  </GridItem>
                </GridContainer>
              </Collapse>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Divider style={{ marginTop: "25px", marginBottom: "10px" }} fullWidth />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Button color="primary" className={classes.backButton} onClick={() => {
                if (props.edit) {
                  props.previousStep()
                } else {
                  goBack()
                }
              }}>
                Back
              </Button>
              <Button color="primary" className={classes.addActions} onClick={submitData}>
                {submitBtnLabel}
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.processModal,
        }}
        open={submitting}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          <h4 className={classes.modelTag}> {submitLabel}</h4>
        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          // paper: classes.processModal,
        }}
        open={openPDFeViewer}
        TransitionComponent={Transition}
        onClose={() => {
          setOpenPDFViewer(false);
          setDocumentViewerData("");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <Document
            file={documentViewerData}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>Page {pageNumber} of {numPages}</p>
          <Button size="sm" color="primary" onClick={() => {
            if (pageNumber < 2) {
              setPageNumber(1)
            } else {
              let newPageNumber = pageNumber - 1
              setPageNumber(newPageNumber)
            }
          }} >Pervious</Button>
          <Button size="sm" color="primary"
            onClick={() => {
              if (numPages > pageNumber) {
                let newPageNumber = pageNumber + 1
                setPageNumber(newPageNumber)
              } else {
                setPageNumber(numPages)
              }
            }}
          >Next</Button>
        </DialogContent>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.companyLogModal,
        }}
        open={showEditLor}
        TransitionComponent={Transition}
        onClose={() => {
          setShowEditLor(false);
          cancelAddLor()
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>

          <GridContainer>
            <GridItem xs={12} sm={6}>
              <CustomInput
                error={values.lorNameErr}
                labelText={
                  <span>
                    Name <small className={classes.required}>*</small>
                  </span>
                }
                id="lor-name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: lorName,
                  onChange: (e) => {
                    setLorName(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : lorName);
                    verify(libs.validate(e.target.value, "alphabet", 150) ? e.target.value : lorName, "length", "lorNameErr", 3);
                    /*  setLorName(e.target.value);
                     verify(e.target.value, "length", "lorNameErr", 2); */
                  },
                  onBlur: (e) => {
                    setLorName(e.target.value.trim());
                  },
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.lorDesignationErr}
                labelText={
                  <span>
                    Designation <small className={classes.required}>*</small>
                  </span>
                }
                id="lor-designation"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: lorDesignation,
                  onChange: (e) => {
                    setLorDesignation(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : lorDesignation);
                    verify(libs.validate(e.target.value, "alphanumeric", 150) ? e.target.value : lorDesignation, "length", "lorDesignationErr", 3);
                    /*  setLorDesignation(e.target.value);
                     verify(e.target.value, "length", "lorDesignationErr", 2); */
                  },
                  onBlur: (e) => {
                    setLorDesignation(e.target.value.trim());
                  },
                  disabled:true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.lorCPRErr}
                labelText={
                  <span>
                    CPR <small className={classes.required}>*</small>
                  </span>
                }
                id="lor-cpr"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: lorCPR,
                  onChange: (e) => {
                    verify(e.target.value, "cpr", "lorCPRErr", 9);
                  },
                  onBlur: (e) => {
                    findExistingCPR(e.target.value)

                  },
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.lorMobileErr}
                labelText={
                  <span>
                    Mobile <small className={classes.required}>*</small>
                  </span>
                }
                id="lor-mobile"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: lorMobile,
                  onChange: (e) => {
                    const re = /^[0-9\b]+$/;
                    var res = e.target.value.substring(0, 1)
                    if (res == 3 || res == 6 || e.target.value == "") {
                      if (e.target.value === "" || re.test(e.target.value)) {
                        if (e.target.value.length <= 8) {
                          setLorMobile(e.target.value);
                        }
                      }
                    }
                    verify(e.target.value, "length", "lorMobileErr", 8);
                  },
                  onBlur: (e) => {
                    findExistingMobile(e.target.value)
                  },
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.lorLandlineErr}
                labelText={
                  <span>
                    LandLine
                  </span>
                }
                id="lor-landline"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: lorLandLine,
                  onChange: (e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      if (e.target.value.length <= 8) {
                        setLorLandLine(e.target.value);
                      }
                    }
                    if (e.target.value.length == 0) {
                      setValues({ ...values, lorLandlineErr: false })
                    } else {
                      verify(e.target.value, "length", "lorLandlineErr", 8);
                    }
                  },
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>

              <FormControl className={classes.selectFormControl} fullWidth>
                <InputLabel className={classes.dateLabel}>
                  <span>
                    Date of Birth <small className={classes.required}>*</small>
                  </span>
                </InputLabel>
                <TextField
                  error={values.lorDobErr}
                  className={classes.dateInput}
                  type="date"
                  inputProps={{
                    autoComplete: "off",
                    // disabled: props.edit,
                    type: "date",
                    max: moment(new Date()).format("YYYY-MM-DD"),
                    value: lorDob,
                    onChange: (e) => {
                      verify(e.target.value, "length", "lorDobErr", 3);
                      setLorDob(e.target.value);
                      // if (moment(e.target.value).isAfter(moment(new Date()))) {
                      //   setValues({ ...values, dateOfIncorporationErr: true })
                      //   openSnackBar("Please enter valid lorDob date.")
                      // } else {
                      //   handleEditData("IncorporationDetails", "DateOfIncorporation", null, e.target.value)
                      // }
                    },
                    onBlur: (e) => {
                      verify(e.target.value, "length", "dateOfIncorporationErr", 3);
                      if (moment(e.target.value).isAfter(moment(new Date()))) {
                        setValues({ ...values, dateOfIncorporationErr: true })
                      }
                    },
                  }}
                />
              </FormControl>

            </GridItem>
            <GridItem xs={12} sm={3}>
              <FormControl fullWidth>
                {/* error={values.lorTitleErr} */}
                <InputLabel htmlFor="lor-title">TITLE <small className={classes.required}>*</small></InputLabel>
                <Select
                  id="lor-title"
                  value={lorTitle}
                  onChange={(e) => {
                    setLorTitle(e.target.value.trim());
                    verify(e.target.value, "length", "titleErr", 2);
                  }}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                >
                  <MenuItem value="" disabled>Select Title</MenuItem>
                  <MenuItem value="MR">Mr.</MenuItem>
                  <MenuItem value="MRS">Mrs.</MenuItem>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="lor-gender">GENDER</InputLabel>
                <Select
                  id="lor-gender"
                  value={lorGender}
                  onChange={(e) => {
                    setLorGender(e.target.value.trim());
                    verify(e.target.value, "length", "titleErr", 2);
                  }}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                >
                  <MenuItem value="" disabled>Select Gender</MenuItem>
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                  <MenuItem value="O">Other</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                error={values.lorEmailErr}
                labelText={
                  <span>
                    Email <small className={classes.required}>*</small>
                  </span>
                }
                id="lor-email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  value: lorEmail,
                  onChange: (e) => {
                    setLorEmail(e.target.value);
                    verify(e.target.value, "email", "lorEmailErr", 3);
                  },
                  onBlur: (e) => {
                    setLorEmail(e.target.value.trim());
                    findExistingEmail(e.target.value)
                  },
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              {lorIDProofFileName !== "" ? (
                <CustomInput
                  labelText="ID Proof"
                  id="dir-id-proof"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    autoComplete: "off",
                    readOnly: true,
                    value: lorIDProofFileName,
                  }}
                />
              ) : (
                <>
                  <br /> <br />
                  <Dropzone onDrop={uploadLorID}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button size="sm" fullWidth color={lorUpButtonColor}>
                          ID Proof
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </>
              )}

            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <br />  <br />
              <Tooltip placement="right" title="Delete ID">
                <IconButton
                  onClick={() => deleteID()}
                  style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                  aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>

              <Button
                color="primary"
                className={classes.addOwnerActions}
                size="sm"
                simple
                onClick={() => {
                  setShowEditLor(false)
                  cancelAddLor()
                }}>
                Cancel
              </Button>


              <Button color="primary" className={classes.addOwnerActions} size="sm" onClick={() => { saveLor(lorEditID) }}>
                Save
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>

      {
        openImageViewer && (
          <Lightbox
            mainSrc={documentViewerData}
            onCloseRequest={() => setOpenImageViewer(false)}
          />
        )
      }

    </div >
  );
}
