import React from "react";
import config from "config.js"
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Paper from '@material-ui/core/Paper';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import moment from "moment";
import JSONPretty from 'react-json-pretty';
import { Button } from "@material-ui/core";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { IconButton } from "@material-ui/core";

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    overflowX: "scroll"
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,

    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "1500px",
    },
    tabContent: {
        maxHeight: "60vh",
        overflowX: "scroll"

    },
    logPaper: {
        padding: "15px",

    },

}));

export default function CorporateLogs(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [pageNo, setPageNo] = React.useState(0);
    const [showLoadMore, setShowLoadMore] = React.useState(true);

    const [corporatePortalLogData, setCorporatePortalLogData] = React.useState([]);
    const [noCorporatePortalLogData, setNoCorporatePortalLogData] = React.useState(false);
    const [corporatePortalM2pLogData, setCorporatePortalM2pLogData] = React.useState([]);
    const [noCorporatePortalM2pLogData, setNoCorporatePortalM2pLogData] = React.useState(false);
    const logRef = React.useRef(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log(newValue)
        getLogs(newValue, props.crNo)
    };

    const handleChangeIndex = (index) => {
        setValue(index);

    };
    function parseNestedJSONString(jsonString) {
        try {
            const parseNested = (str) => {
                let result;
                try {
                    result = JSON.parse(str);
                } catch (error) {
                    if (typeof str === 'string' && str.startsWith('"') && str.endsWith('"')) {
                        return parseNested(str.slice(1, -1));
                    } else {
                        return str;
                    }
                }
                if (typeof result === 'string') {
                    return parseNested(result);
                }
                if (typeof result === 'object') {
                    for (let key in result) {
                        result[key] = parseNested(result[key]);
                    }
                }
                return result;
            };
            return parseNested(jsonString);
        } catch (error) {
            console.error('Error parsing JSON string:', error);
            return null;
        }
    }

    const copyToClipboardNew = (e) => {
        navigator.clipboard.writeText(JSON.stringify({
            url: e.url_called ? e.url_called : e.url ? e.url : "",
            request: parseNestedJSONString(e.request),
            response: parseNestedJSONString(e.response)
        }, null, 4));
    };


    React.useEffect(() => {
        if (props.crNo !== null) {
            getLogs(0, props.crNo)
            setValue(0)
            setCorporatePortalLogData([]);
            setNoCorporatePortalLogData(false);

            setCorporatePortalM2pLogData([])
            setNoCorporatePortalM2pLogData(false)
        } else {
            setCorporatePortalLogData([]);
            setNoCorporatePortalLogData(false);

            setCorporatePortalM2pLogData([])
            setNoCorporatePortalM2pLogData(false)
        }

    }, [props.crNo]);

    const fetchCorporatePortalLogs = (crNo, page) => {
        //function to fetch log of a customer
        if ((crNo && page) || page == 0) {
            let data = {
                CRNo: crNo,
                pageNo: page,
                pageSize: 20
            }
            axios.post(`${config.url().baseURL}/get-corporate-portal-logs`, data).then((resp) => {
                let respData = resp.data;
                setPageNo(page + 1)
                if (!respData.err) {
                    if (page == 0) {
                        setCorporatePortalLogData(respData.data);
                    } else {
                        setCorporatePortalLogData(p => [...p, ...respData.data])
                    }
                } else {
                    if (page == 0) {
                        setNoCorporatePortalLogData(true)
                    } else {
                        setShowLoadMore(false)
                    }
                }
            });
        }
    };
    const fetchCorporatePortalM2pLogs = (crNo, page) => {
        //function to fetch log of a customer
        if ((crNo && page) || page == 0) {
            let data = {
                CRNo: crNo,
                pageNo: page,
                pageSize: 20
            }
            axios.post(`${config.url().baseURL}/get-corporate-portal-m2p-logs`, data).then((resp) => {
                let respData = resp.data;
                setPageNo(page + 1)
                if (!respData.err) {
                    if (page == 0) {
                        setCorporatePortalM2pLogData(respData.data);
                    } else {
                        setCorporatePortalM2pLogData(p => [...p, ...respData.data])
                    }
                } else {
                    if (page == 0) {
                        setNoCorporatePortalM2pLogData(true)
                    } else {
                        setShowLoadMore(false)
                    }
                }
            });
        }
    };

    const getLogs = (id, crNo) => {
        //function to get logs
        setPageNo(0)
        setShowLoadMore(true)
        switch (id) {
            case 0: fetchCorporatePortalLogs(crNo, 0); break;
            case 1: fetchCorporatePortalM2pLogs(crNo, 0); break;

            default: break;
        }
    }

    return (
        <div className={classes.companyLogModal} style={{ padding: "0 1.6rem" }}>
            <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none', display: 'block' }}>
                <div style={{ overflowX: 'auto' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        aria-label="full width tabs example"
                    >
                        <Tab label="Corporate Portal Logs" {...a11yProps(0)} />
                        <Tab label="M2P Logs" {...a11yProps(0)} />

                    </Tabs>
                </div>

            </AppBar>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                className={classes.tabContent}
                overflowX="scroll"
                onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Collapse
                        in={noCorporatePortalLogData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noCorporatePortalLogData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={corporatePortalLogData.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={corporatePortalLogData.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer} ref={logRef} id="log-data-id">
                                {corporatePortalLogData.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log.logID}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon s />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.createdAt ? (
                                                    <>
                                                        Date : {moment(log.createdAt).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.createdAt).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.request.method ? (
                                                    <>
                                                        Type : {log.request.method}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.request.url ? (
                                                    <>
                                                        URL : {log.request.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.duration ? (
                                                    <>
                                                        Duration : {log.duration}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => fetchCorporatePortalLogs(props.crNo, pageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Collapse
                        in={noCorporatePortalM2pLogData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <h4>No Log Found.</h4>
                    </Collapse>
                    <Collapse
                        in={!noCorporatePortalM2pLogData}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <Collapse
                            in={corporatePortalM2pLogData.length == 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                        </Collapse>
                        <Collapse
                            in={corporatePortalM2pLogData.length !== 0}
                            style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                            {...(true ? { timeout: 500 } : {})}>
                            <div className={classes.logContainer} ref={logRef} id="log-data-id">
                                {corporatePortalM2pLogData.map((log) => {
                                    return (
                                        <>
                                            <Paper elevation={3} className={classes.logPaper} >
                                                {log._id ? (
                                                    <>
                                                        Log ID :{log.logID}
                                                        <IconButton style={{ transformOrigin: "0 0 0", float: "right" }} onClick={() => { copyToClipboardNew(log) }}>
                                                            <FileCopyIcon s />
                                                        </IconButton>
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.createdAt ? (
                                                    <>
                                                        Date : {moment(log.createdAt).format("DD/MM/YYYY")}
                                                        <br />
                                                        Time : {moment(log.createdAt).format("hh:mm:ss A")}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.request.method ? (
                                                    <>
                                                        Type : {log.request.method}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.request.url ? (
                                                    <>
                                                        URL : {log.request.url}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.duration ? (
                                                    <>
                                                        Duration : {log.duration}
                                                        <br />
                                                    </>
                                                ) : null}
                                                {log.response ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Response</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.response} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                                {log.request ? (
                                                    <Accordion  >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography>Request</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails  >
                                                            <div style={{ width: "750px !important" }}>
                                                                <JSONPretty data={log.request} />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ) : null}
                                            </Paper>
                                            <br />
                                        </>
                                    );
                                })}
                                {
                                    showLoadMore ? <Button fullWidth onClick={() => fetchCorporatePortalM2pLogs(props.crNo, pageNo)} >Load More</Button> : null
                                }
                            </div>

                        </Collapse>
                    </Collapse>
                </TabPanel>
            </SwipeableViews>
        </div >
    );
}
