import React, { useState, useEffect } from "react";
import GridItem from "components/Grid/GridItem";
import Collapse from "@material-ui/core/Collapse";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from "react-spinners/PropagateLoader";
import MUIDataTable from "mui-datatables";
import { css } from "@emotion/react";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import SearchHeader from "./components/Header";
import axios from "axios";
import config from "config";
import Swal from 'sweetalert2';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    ...modalStyle(theme),
}));

const loaderCss = css`
    display: block;
    margin: 32px auto;
    border-color: red;
`;

export default function FlossReport() {
    const [showLoader, setShowLoader] = useState(false);
    const [showFlossTable, setShowFlossTable] = useState(true); 
    const [flossDetails, setFlossDetails] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [type, setType] = useState("");

    const classes = useStyles();

    const columns = [
        // { name: "createdAt", label: "Loan Disbursement Date" },
        {
            name: "createdAt",
            label: "Loan Disbursement Date",
            options: {
                customBodyRender: (value) => 
                    value ? moment(value).format("DD MMM YYYY, hh:mm:ss A") : "N/A",
            },
        },

        { name: "cpr", label: "CPR" },
        { name: "walletNumber", label: "Wallet Number" },
        { name: "name", label: "Name" },
        { name: "requestedLoanAmount", label: "Loan Amount (BHD)" },
        {
            name: "dueDate",
            label: "Repayment Due Date",
            options: {
                customBodyRender: (value) => value ? moment(value).format("DD MMM YYYY, hh:mm:ss A") : "N/A",
            },
        },
                {
            name: "loanDue",
            label: "Loan Due",
            options: {
                customBodyRender: (value) => (
                    <span style={{ color: value > 0 ? "red" : "green" }}>
                        {value}
                    </span>
                ),
            },
        },
        { name: "loanCollected", label: "Loan Collected" },
        { name: "feeDue", label: "Fee Due" },
        { name: "feeCollected", label: "Fee Collected" },
        { name: "latestCollectionDate", label: "Latest Collection Date" },
        { name: "totalOutstanding", label: "Total Outstanding" },
        { name: "status", label: "Status" },
    ];

    const options = {
        responsive: "vertical",
        selectableRows: "none",
        rowsPerPage: 10,
    };

    // Fetching floss details whenever filters change
    useEffect(() => {
        fetchFlossDetails();
    }, [type]);

    const fetchFlossDetails = async () => {
        setShowLoader(true);
        try {
            const payload = {
                startDate: fromDate,
                endDate: toDate,
                type,
            };
            const response = await axios.post(
                `${config.url().lmsUrl}/v1/user/cxportal/get-all-loans`,
                payload,
                {
                    headers: {
                        'x-provider': 'flooss', 
                        'Content-Type': 'application/json',
                    }
                }
            );
            const resp = response?.data;
            if (resp.status.code !== "200") {
                throw new Error(resp.message || "Something went wrong");
            }
            setFlossDetails(resp.data);
        } catch (error) {
            console.error("Error fetching floss details:", error);
            Swal.fire({
                title: "Error",
                text: error.message || "An error occurred while fetching data.",
                icon: "error",
                confirmButtonText: "OK",
            });
        } finally {
            setShowLoader(false);
        }
    };

    // Handler for search filter changes
    const handleFilterChange = (value, field) => {
        if (field === "fromDate") {
            setFromDate(value);
        } else if (field === "toDate") {
            setToDate(value);
        } else if (field === "referenceNumber") {
            setType(value);
        } else if (field === "reset") {
            setFromDate("");
            setToDate("");
            setType("");
        }
    };

    return (
        <div>
            <SearchHeader
                fromDate={fromDate}
                toDate={toDate}
                referenceNumber={type}
                onChangeFilter={handleFilterChange}
                handleSearch={fetchFlossDetails}
            />
            <GridItem xs={12} sm={12} md={12}>
                <Collapse
                    in={showLoader}
                    style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                    timeout={500}
                >
                    <PropagateLoader color="#004fa4" loading={true} css={loaderCss} size={15} />
                </Collapse>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Collapse
                    in={showFlossTable}
                    style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                    timeout={500}
                >
                    <Card>
                        <CardHeader color="primary" style={{ textAlign: "left" }}>
                            <h4 className={classes.cardTitleWhite}>Floss Status Report</h4>
                        </CardHeader>
                        <CardBody>
                            <MUIDataTable
                                data={flossDetails}
                                columns={columns}
                                options={options}
                            />
                        </CardBody>
                    </Card>
                </Collapse>
            </GridItem>
        </div>
    );
}
