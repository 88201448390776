import React from 'react';
import {
    Grid,
    Paper,
    InputBase,
    Button,
    Tooltip,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SearchIcon from '@mui/icons-material/Search';

// Styled components for reusability and consistency
const SearchLabel = styled('p')({
    margin: 0,
    color: "#144b85",
    fontWeight: "500",
    fontSize: "0.9rem",
});

const InputContainer = styled(Paper)({
    padding: "4px",
    display: "flex",
    alignItems: "center",
    height: "45px",
    boxShadow: "none",
    border: "1px solid #ccc",
});

const SearchInput = styled(InputBase)({
    flex: 1,
    padding: "5px 10px",
});

const ActionButton = styled(Button)({
    backgroundColor: '#0057b8',
    color: 'white',
    minWidth: '48px',
    height: '45px',
    '&:hover': {
        backgroundColor: '#004494',
    },
});

const ResetButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
});

export default function Header(props) {
    const {
        onChangeFilter,
        fromDate,
        toDate,
        referenceNumber,
        handleSearch
    } = props;

    // Reset the search filters
    const resetSearch = () => {
        onChangeFilter('', 'reset');
    };

    // Placeholder function for Snackbar logic
    const openSnackBar = (message) => {
        // alert(message); // Replace with your snackbar logic
    };

    return (
        <div>
            <Grid container spacing={2} alignItems="center">
                {/* From Date Field */}
                <Grid item xs={12} sm={6} md={4}>
                    <SearchLabel>From Date</SearchLabel>
                    <InputContainer>
                        <SearchInput
                            type="date"
                            inputProps={{
                                max: toDate || moment().format('YYYY-MM-DD'), // From Date cannot be in the future
                            }}
                            value={fromDate}
                            onChange={(event) => onChangeFilter(event.target.value, "fromDate")}
                            onBlur={(event) => {
                                if (moment(event.target.value).isAfter(moment())) {
                                    openSnackBar("Please enter a valid date.");
                                }
                            }}
                        />
                    </InputContainer>
                </Grid>

                {/* To Date Field */}
                <Grid item xs={12} sm={6} md={4}>
                    <SearchLabel>To Date</SearchLabel>
                    <InputContainer>
                        <SearchInput
                            type="date"
                            inputProps={{
                                min: fromDate || "1900-01-01",
                                max: fromDate
                                    ? moment.min(
                                        moment(fromDate).add(3, 'months'),
                                        moment()
                                    ).format('YYYY-MM-DD')
                                    : moment().format('YYYY-MM-DD'),
                            }}
                            value={toDate}
                            onChange={(event) => onChangeFilter(event.target.value, "toDate")}
                            onBlur={(event) => {
                                if (fromDate && moment(event.target.value).isSameOrBefore(moment(fromDate))) {
                                    openSnackBar("To Date must be greater than From Date.");
                                }
                            }}
                        />
                    </InputContainer>
                </Grid>

                {/* Reference Number Field */}
                <Grid item xs={12} sm={12} md={4}>
                    <SearchLabel>Type</SearchLabel>
                    <InputContainer>
                        <FormControl fullWidth>
                            <Select
                                id="type-select"
                                value={referenceNumber || "All"}
                                onChange={(e) => onChangeFilter(e.target.value, "referenceNumber")}
                                displayEmpty
                                disableUnderline
                            >
                                <MenuItem value="All">All</MenuItem>
                                <MenuItem value="Disbursement">Disbursement</MenuItem>
                                <MenuItem value="Repayment">Repayment</MenuItem>
                            </Select>
                        </FormControl>
                        <ActionButton onClick={handleSearch}>
                            <SearchIcon fontSize="medium" />
                        </ActionButton>
                    </InputContainer>
                </Grid>
            </Grid>

            {/* Reset Button */}
            <ResetButtonContainer>
                <Tooltip title="Reset">
                    <IconButton onClick={resetSearch}>
                        <RotateLeftIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>
            </ResetButtonContainer>
        </div>
    );
}
